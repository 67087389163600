import React, { useCallback } from "react";
import { Switch, Checkbox, Popper, Grow, MenuList, ClickAwayListener, Select, Menu, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Chip, FormControl, Accordion, AppBar, Toolbar, IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, TextField,CircularProgress, LinearProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, TableSortLabel, Table, TableRow, TableHead, Card, CardContent, Tab, Tabs, TextareaAutosize, Divider, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import io from 'socket.io-client';
import SyncIcon from '@mui/icons-material/Sync';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GoogleIcon from '@mui/icons-material/Google';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import Add from "@mui/icons-material/Add";
import StopIcon from '@mui/icons-material/Stop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import AlertTitle from '@mui/material/AlertTitle';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import MoreVert from '@mui/icons-material/MoreVert';
import Delete from '@mui/icons-material/Delete';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import * as loom from '@loomhq/loom-embed'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArticleIcon from '@mui/icons-material/Article';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ArrowBack, Computer, Share, ExpandLess, NavigateBefore, NavigateNext, ExpandMore, Check, Clear, MailOutlineOutlined, ContentCopyOutlined, CloseFullscreen, OpenInFull, Edit } from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';
import EditIconSvg from './assets/svg/EditIcon.svg';
import EditIconNewSvg from './assets/svg/EditIconNew.svg';
import DeleteIconSvg from './assets/svg/DeleteIcon.svg';
import DeleteIconNewSvg from './assets/svg/DeleteIconNew.svg';
import PlayIcon from './assets/svg/PlayIcon.svg';
import MakeInterviewUnique from './assets/svg/MakeInterviewUnique.svg';
import CreateSession from './assets/svg/CreateSession.svg';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneIcon from '@mui/icons-material/Phone';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoomLogoSvg from './LoomLogo.svg';
import CamLogoSvg from './CamLogo.svg';
import ErrorIcon from '@mui/icons-material/Error';
import TranslateIcon from '@mui/icons-material/Translate';

import TooltipCustom from "./components/common/TooltipCustom";
import ChipTag from "./components/common/ChipTag";
import WayfasterLogo from "./wayfaster-logo-w.svg";

import Switcher from "./components/team/Switcher";
import { Sidebar } from "./components/nav/Sidebar";
import CandidateProfile from "./components/candidate/CandidateProfile";

import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom'
import {
  useAuth0
} from '@auth0/auth0-react'
import { ArrowDropDown, CheckCircle, Circle, QueryStats, ThreeP } from "@mui/icons-material";
import FormField from "./components/common/FormField";
import FormSelect from "./components/common/FormSelect";
import api from "./api/api";
import CreateInterviewJD from "./pages/CreateInterviewJD";
import CreateInterviewTemplate from "./pages/CreateInterviewTemplate";
import CreateInterviewHome from "./pages/CreateInterviewHome";
import TableSection from "./components/table/TableSection";

import SimulatedPreview from "./components/interview/SimulatedPreview";
import Papa from "papaparse";
import FormEditable from "./components/common/FormEditable";
import LanguageSelector from "./components/interview/LanguageSelector";

import { SECTIONS_OPTIONS, supportedLanguages, Language, SessionDecision } from "./util/constants";
import { buildUniqueSessionLink, normalizeScore, getColorFromScore, getColorBgFromScore } from "./util/interviews";

ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });


var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'

var env = String(process.env.REACT_APP_ENV)
console.log('env', env)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
}

const LanguageDetect = require('languagedetect');
const langDetector = new LanguageDetect();

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {},
  scorecard: [],
  scoring_pending: false
}

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'system_design_event_ticketing'
var interviewerName = 'Steve'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}
var allSysDesignParam = urlParams.get('all_sys_design');
if(!allSysDesignParam) {
  allSysDesignParam = 'false'
}

if(globalInterviewType === 'eve') {
  interviewerName = 'Isabelle'
}

const SessionStatus = {
  NOT_STARTED: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  IN_PROGRESS: 4
}

const SessionStatusString = {
  [SessionStatus.NOT_STARTED]: 'Not Started',
  [SessionStatus.INCOMPLETE]: 'Incomplete',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.IN_PROGRESS]: 'In Progress'
}


const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  // console.log(rowId)

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragHandleIcon />
    </IconButton>
  )
}

const detectLanguageMatch = (question, language) => {
  const detectedLanguage = langDetector.detect(question, 2);
  // shows as
  // [ [ 'english', 0.5969230769230769 ], [ 'hungarian', 0.407948717948718 ] ]
  if (detectedLanguage.length > 0) {
    return detectedLanguage[0][0] === language.toLowerCase();
  }
  return false;
}

const DraggableRow = ({ idx, row, selected, setSelectedPreview, stateAddQuestions, setAddQuestions, setShowAddQuestionInput, language }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        <div className="d-flex flex-row my-auto" style={{ marginRight: '8px', marginLeft: '8px' }}>
          {/* { !detectLanguageMatch(row.question, language ? language : Language.ENGLISH) && 
            <TooltipCustom title={
              <>
                <p className="m-auto">
                  We've detected that this question may not be in the selected language for this interview.
                </p>
              </>
            }>
              <ErrorIcon className="my-auto" sx={{ color: 'orange', fontSize: '14px', marginRight: '8px' }} />
            </TooltipCustom>
          } */}
          {row.question}
        </div>
      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{row.follow_ups}</TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Preview */}
            <IconButton
              onClick={() => {
                if (!selected) setSelectedPreview(row);
              }}
              style={{ color: selected ? 'var(--color-primary)' : 'var(--color-main)', marginRight: '4px' }}
            >
              <TextSnippetIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {/* Edit */}
            <IconButton
              onClick={() => {
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    numFollowUps: row.follow_ups,
                    id: row.id,
                    editIndex: idx,
                  },
                });
                setShowAddQuestionInput(true)
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={() => {
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}


export const CreateInterview = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [activeStep, setActiveStep] = useState(-1)
  const [skipped, setSkipped] = useState(new Set())
  const [errMsgs, setErrMsgs] = useState('')
  const [creationFlow, setCreationFlow] = useState('');
  const [sections, setSections] = useState(SECTIONS_OPTIONS)
  const [newSectionName, setNewSectionName] = useState("")
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
  const [interviewPreview, setInterviewPreview] = useState([])

  // "Role Background"
  const [roleBackground, setRoleBackground] = useState({
    interviewTitle: '',
    assessmentLanguage: Language.ENGLISH,
    jobTitle: '',
    interviewLength: 10
  })

  // "Add Questions"
  const [addQuestions, setAddQuestions] = useState({
    questions: [],
    currentQuestion: {
      text: 'What has been the highlight of your past role?',
      numFollowUps: 1,
      editIndex: -1,
    },
    edit: false
  })
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false)

  // "Add Outputs"
  const [addOutputs, setAddOutputs] = useState({
    outputs: [{ key: 'past_employers', data_type: 'List', extraction_instructions: 'get a list of past employers the candidate has worked for'}],
    currentOutput: {
      output: '',
      data_type: 'List',
      extraction_instructions: ''
    },
    editIndex: -1
  })
  const [showAddOutputInput, setShowAddOutputInput] = useState(false)

  // "Add Scoring"
  const [addScoring, setAddScoring] = useState({
    signals: [
      {
        "signal": "English Proficiency",
        "1PointCriteria": "\t•\tClarity: Speaks unclearly with strong accents or speech issues that significantly hinder understanding.\n\t•\tVocabulary: Uses very basic vocabulary with little to no construction-specific terms and frequent major errors.\n\t•\tComprehension: Struggles to understand even basic questions and instructions, requiring frequent repetition and clarification.\n\t•\tResponsiveness: Responds inappropriately or with significant delays, showing limited understanding of the conversation context.",
        "2PointCriteria": "\t•\tClarity: Speaks with noticeable accents or speech issues that occasionally hinder understanding.\n\t•\tVocabulary: Uses basic vocabulary with limited construction-specific terms and frequent minor errors.\n\t•\tComprehension: Understands basic questions and instructions but often needs repetition or clarification for more complex ones.\n\t•\tResponsiveness: Responds to questions with some delays or hesitations, showing a partial understanding of the conversation context.",
        "3PointCriteria": "\t•\tClarity: Speaks clearly with minor accents or speech issues that do not significantly hinder understanding.\n\t•\tVocabulary: Uses a good range of vocabulary, including some construction-specific terms, with occasional minor errors.\n\t•\tComprehension: Understands most questions and instructions with little need for repetition or clarification.\n\t•\tResponsiveness: Responds appropriately to questions and demonstrates a good understanding of the conversation context.",
        "4PointCriteria": "\t•\tClarity: Speaks very clearly and articulately, with no noticeable accent or speech impediments that hinder understanding.\n\t•\tVocabulary: Uses a wide range of vocabulary, including construction-specific terms and technical language, accurately and appropriately.\n\t•\tComprehension: Fully understands questions and instructions immediately, without needing repetition or clarification.\n\t•\tResponsiveness: Responds promptly and appropriately to questions, showing a thorough understanding of the conversation context.\n",
        "scale": 4,
        "weight": 100
      }
    ],
    currentSignal: {
      'signal': '',
      'scale': 4,
      'description': '',
      '1PointCriteria': '',
      '2PointCriteria': '',
      '3PointCriteria': '',
      '4PointCriteria': '',
      'weight': 100, // percentage,
      'editIndex': -1
    }
  })
  const [generatedCriteria, setGeneratedCriteria] = useState({});
  const [showAddSignalInput, setShowAddSignalInput] = useState(false)
  const [showAddSignalAssistInput, setShowAddSignalAssistInput] = useState(false)

  // "Review Test"
  const [reviewTest, setReviewTest] = useState({
    antiCheating: true,
    videoRecording: true,
    audioRecording: true,
    prompt: ''
  })

  const [expanded, setExpanded] = useState(false)

  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const copyFrom = urlParams.get('copy_from');
    if(!isLoading && isAuthenticated && copyFrom !== undefined && !fetched) {
      setFetched(true)
      var userId = user.email
      const teamId = localStorage.getItem('teamId')
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      api.getInterview(copyFrom, userId, teamId)
      .then((response) => {
        console.log(response)
        let questions = response.data.questions
        questions.shift()

        setRoleBackground({
          assessmentLanguage: Language.ENGLISH,
          interviewTitle: response.data.title,
          jobTitle: '',
          interviewLength: 10
        })

        setAddQuestions({
          ...addQuestions,
          questions: questions,
        })

        setAddScoring({
          ...addScoring,
          signals: response.data.scorecard
        })

        setAddOutputs({
          ...addOutputs,
          outputs: response.data.structuredOutputs
        })

        setReviewTest({
          antiCheating: response.data.antiCheating,
          videoRecording: response.data.videoRecording,
          audioRecording: response.data.audioRecording,
          prompt: response.data.prompt
        })

        // redirect to self-create flow if questions populated from copy
        setCreationFlow('self');
        setActiveStep(0);
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [isLoading, isAuthenticated, user, fetched, setFetched,
  addQuestions, setAddQuestions, addScoring, setAddScoring, addOutputs, setAddOutputs, setReviewTest]);

  const steps = ['Details', 'Questions', 'Scoring', 'Outputs', 'Review'];
  const navigate = useNavigate();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )


  const createInterview = () => {
    if (user) {
      const interviewConfig = {
        title: roleBackground.interviewTitle,
        length: roleBackground.interviewLength,
        language: roleBackground.assessmentLanguage,
        jobTitle: roleBackground.jobTitle,
        questions: addQuestions.questions,
        scorecard: addScoring.signals,
        prompt: reviewTest.prompt,
        structuredOutputs: addOutputs.outputs,
      };

      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const payload = {
        userId: userId,
        teamId: localStorage.getItem('teamId'),
        interviewConfig: interviewConfig,
      };
      api
        .createInterview(payload)
        .then((response) => {
          console.log(response.data);
          navigate("/interviews/" + response.data.id);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('User not logged in');
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const interviewDetailsValid = () => {
    let valid = true
    let msgs = []

    if(roleBackground.assessmentLanguage.length === 0 || roleBackground.interviewTitle.length === 0 || roleBackground.jobTitle.length === 0) {
      console.log(roleBackground)
      valid = false
      msgs.push('Please fill in all fields')
    }

    if(roleBackground.interviewLength < 5 || roleBackground.interviewLength > 30) {
      valid = false
      msgs.push('Max Interview length must be at least 5 minutes and no more than 30.')
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const questionDetailsValid = () => {
    let valid = true
    let msgs = []

    if(addQuestions.questions.length === 0) {
      valid = false
      msgs.push('You must add at least one question before proceeding.')
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const scoringDetailsValid = () => {
    let msgs = []
    let valid = true

    // check that percentages == 100
    console.log(addScoring.signals)
    let totalWeight = addScoring.signals.reduce((total, signal) => total + signal.weight, 0);
    console.log(totalWeight)
    if(addScoring.signals.length > 0 && totalWeight !== 100) {
      valid = false;
      msgs.push('The total weight of all skills must add up to 100%.');
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const outputsDetailsValid = () => {
    let msgs = []
    let valid = true

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const reviewValid = () => {
    let valid = true
    let msgs = []

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // form validation
    if(activeStep === 0) {
      // validate Interview Details
      const { isValid, msgs } = interviewDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 1) {
      // validate question details
      const { isValid, msgs } = questionDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 2) {
      // validate scoring details
      const { isValid, msgs } = scoringDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 3) {
      // validate outputs details
      const { isValid, msgs } = outputsDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 4) {
      // validate review test details
      const { isValid, msgs } = reviewValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
      createInterview();
    }

    setErrMsgs([])
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setCreationFlow('')
    }
    setErrMsgs([])
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setAddQuestions((prevState) => {
        const oldIndex = prevState.questions.findIndex((question) => question.id === active.id);
        const newIndex = prevState.questions.findIndex((question) => question.id === over.id);
        return {
          ...prevState,
          questions: arrayMove(prevState.questions, oldIndex, newIndex),
        };
      });
    }
  };

  const renderFlowPrompt = () => {
    return <CreateInterviewHome setCreationFlow={setCreationFlow}/>
  }

  const renderJDUpload = () => {
    return <CreateInterviewJD
      setCreationFlow={setCreationFlow}
      setActiveStep={setActiveStep}
      setAddQuestions={setAddQuestions}
      setShowAddQuestionInput={setShowAddQuestionInput}
      setRoleBackground={setRoleBackground}
      roleBackground={roleBackground}
      showInterviewSetup={true}
      sx={{ width: '800px' }}
    />
  }

  const renderTemplateFlow = () => {
    return <CreateInterviewTemplate
      setCreationFlow={setCreationFlow}
      setActiveStep={setActiveStep}
      setAddQuestions={setAddQuestions}
      setAddScoring={setAddScoring}
      setAddOutputs={setAddOutputs}
      setShowAddQuestionInput={setShowAddQuestionInput}
      setRoleBackground={setRoleBackground}
      roleBackground={roleBackground}
      showInterviewSetup={true}
      sx={{ width: '800px' }}
    />
  }

  const renderRoleBackground = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
        <Box container className="d-flex flex-col"
          style={{ width: '60%', margin: 'auto', border: '1px solid lightgray', padding: '24px', borderRadius: '6px' }}>
          <h3 className="section" style={{ marginTop: 0 }}>Interview Details</h3>

          <FormField
            required
            label="Interview Title"
            tooltip
            tooltipTitle={<>
              <p className="m-auto">
                This is the title of the interview that only you will see.
              </p>
            </>}
            placeholder="Retail Sales Associate Case Study"
            value={roleBackground.interviewTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}
          />

          <FormField
            required
            label="Job Title"
            tooltip
            tooltipTitle={<>
              <p className="m-auto">
                This is the title of the job that will be displayed to the candidate.
              </p>
            </>}
            placeholder="Retail Sales Associate"
            value={roleBackground.jobTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}
            />

          <div className="d-flex flex-col" style={{ marginBottom: '16px' }}>
            <h5 className="input-label">Assessment Language</h5>
            <LanguageSelector currentLanguage={roleBackground.assessmentLanguage} languages={supportedLanguages} 
              clicked={(e) => {setRoleBackground({ ...roleBackground, assessmentLanguage: e })}} />
          </div>

          <FormField
            required
            label="Max Interview Length (minutes)"
            type="number"
            value={roleBackground.interviewLength}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewLength: e.target.value })}}
            />
        </Box>
      </div>
    )
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  const triggerShowAddModal = (type) => {
    setErrMsgs([]);
    switch (type) {
      case 'question':
        return setShowAddQuestionInput(true);
      case 'signal':
        return setShowAddSignalInput(true);
      case 'signal-assist':
        return setShowAddSignalAssistInput(true);
      case 'output':
        return setShowAddOutputInput(true)
      default:
        return;
    }
  }

  const cancelShowAddModal = (type) => {
    setErrMsgs([]);
    switch (type) {
      case 'question':
        return setShowAddQuestionInput(false);
      case 'signal':
        setScoringAssistStep(0);
        setScoringAddTab(0);
        setScoringActiveCriteria('1PointCriteria');
        return setShowAddSignalInput(false);
      case 'signal-assist':
        setScoringAssistStep(0);
        setScoringAddTab(0);
        setScoringActiveCriteria('1PointCriteria');
        return setShowAddSignalAssistInput(false);
      case 'output':
        return setShowAddOutputInput(false)
      default:
        return;
    }
  }

  const saveAddQuestion = async () => {
    const questionText = addQuestions.currentQuestion.text;
    const words = questionText.split(' ');
    let msgs = []

    const numFollowUps = parseInt(addQuestions.currentQuestion.numFollowUps);
    if (isNaN(numFollowUps) || numFollowUps < 0) {
      msgs.push('Number of follow up questions must be at least 0.');
    }

    if(msgs.length > 0 ) {
      setErrMsgs(msgs)
      setShowAddQuestionInput(false)
    } else {
      setErrMsgs([])
      if(addQuestions.currentQuestion.editIndex >= 0) {
        let updatedQuestions = [...addQuestions.questions];
        updatedQuestions[addQuestions.currentQuestion.editIndex] = {
          question: addQuestions.currentQuestion.text,
          follow_ups: addQuestions.currentQuestion.numFollowUps,
          id: addQuestions.currentQuestion.id,
        }
        console.log(updatedQuestions)
        setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', numFollowUps: 1 }})
      } else {
        const randomId = uuidv4()
        setAddQuestions({ questions: [...addQuestions.questions, {
          question: addQuestions.currentQuestion.text,
          follow_ups: addQuestions.currentQuestion.numFollowUps,
          id: randomId,
        }], currentQuestion: { text: 'What has been the highlight of your role?', numFollowUps: 1 }})
      }
      setShowAddQuestionInput(false)
      let editing = addQuestions.currentQuestion.editIndex >= 0;
      let question_id = editing ? addQuestions.currentQuestion.id : uuidv4()
      let question = {
        question: addQuestions.currentQuestion.text,
        follow_ups: addQuestions.currentQuestion.numFollowUps,
        section: addQuestions.currentQuestion.section,
        id: editing ? addQuestions.currentQuestion.id : uuidv4(),
      }
      await updateInAddQuestions(question_id, question);
      setSelectedPreview(question)
    }
  }

  function groupBy(arr, property) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  function sectionSort(a, b) {
    const top_val = 'Introduction'
    const null_val = 'undefined'

    // top_val comes first
    if (a == top_val) return -1;
    if (b == top_val) return 1;


    // nulls sort after anything else
    if (a === null_val) {
      return 1;
    }
    if (b === null_val) {
        return -1;
    }

    // neither matches `value` - compare them normally
    return a.localeCompare(b);
  }

  const setSelectedPreview = async (question) => {
    setCurrentPreviewQuestion({
      topic: question.question,
      follow_ups: question.follow_ups
    })

    if (question.sample) {
      setInterviewPreview(question.sample);
    } else {
      let sample = await callGenerateConversationPreview({
        topic: question.question,
        follow_ups: String(question.follow_ups),
        context_type: 'question_preview',
        job_title: roleBackground.jobTitle, 
        title: roleBackground.interviewTitle
      })
      if (sample) setInterviewPreview(sample);
      else setCurrentPreviewQuestion({});
      console.log('sample', sample)
      question = {...question, sample}
      updateInAddQuestions(question.id, question)
    }
  }

  const updateInAddQuestions = async (rowId, updatedRow) => {
    let updatedQuestions = [...addQuestions.questions];
    let updatedIndex = updatedQuestions.findIndex((question) => question.id == rowId)
    if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedRow
    else updatedQuestions = [...updatedQuestions, updatedRow]
    setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', numFollowUps: 1 }})
  }

  const callGenerateConversationPreview = ({topic, follow_ups, context_type, job_title, title }) => {
    setLoadingPreview(true);
    return api.generateConversationPreview({ topic, follow_ups, context_type, job_title, title })
    .then((response) => {
      setLoadingPreview(false);
      return(response.data.sample)
    })
    .catch((error) => {
      console.log(error)
      setLoadingPreview(false);
      return null;
    })
  }
  
  const thumbVLight = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: '#d6d6d6', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const renderAddQuestions = () => {
    return (
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="d-flex flex-col mx-auto" style={{ flex: 3, paddingTop: '40px', paddingBottom: '80px' }}>
          {/* Interview Questions Display */}
          <Paper className="d-flex flex-col" style={{ flex: 1, minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Interview Questions</h3>
            
              <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                  onClick={() => {triggerShowAddModal('question')}}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Question
              </button>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>Add questions that will be asked during the interview.</p>
            <Scrollbars renderThumbVertical={thumbVLight} style={{ transition: 'all .3s ease', scrollbarColor: '#d6d6d6 white' }}>
              <Grid container>
                <TableContainer style={{ padding: 0 , width: '100%', margin: 0}}>
                  <Table style={{ padding: 0, margin: 0, width: '100%'}}>
                    <TableHead style={{ padding: 0, margin: 0, width: '100%'}}>
                      <TableRow style={{ padding: 0, margin: 0, width: '100%'}}>
                        <TableCell style={{ padding: 0, margin: 0, width: '100%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                          Question
                        </TableCell>
                        <TableCell style={{ display: 'flex', minWidth: '80px', padding: '4px', margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500, width: '72px'}}>
                          Follow-ups
                        </TableCell>
                        <TableCell style={{ fontSize: 16, color: '#656565', border: 'none'}}>
                          {/* Actions */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <SortableContext
                        items={addQuestions.questions.map((question, idx) => {return question.id })}
                        strategy={verticalListSortingStrategy}
                      >
                      {addQuestions.questions.map((question, idx) => (
                        <DraggableRow
                          idx={idx}
                          row={question}
                          selected={currentPreviewQuestion.topic == question.question}
                          setSelectedPreview={setSelectedPreview}
                          setAddQuestions={setAddQuestions}
                          setShowAddQuestionInput={setShowAddQuestionInput}
                          stateAddQuestions={addQuestions}
                          language={roleBackground.assessmentLanguage}
                        />
                      ))}
                      </SortableContext>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Scrollbars>
          </Paper>

          {/* Add Question */}
          <Dialog open={showAddQuestionInput}>
              <DialogTitle>Add Interview Question</DialogTitle>
              <DialogContent>
                <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>Add questions that will be asked during the interview.</p>
                <Grid container>
                  <Grid item xs={12} style={{ height: '215px', marginTop: '25px'}}>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      InputLabelProps={{
                        style: {
                          left: '10px', // Adjusts the horizontal position
                          top: '-5px', // Adjusts the vertical position
                          color: '#656565', // Sets the color of the label
                          position: 'absolute' // Ensures the label can be freely moved
                        }
                      }}
                      fullWidth={true}
                      multiline
                      minRows={8}
                      label="Enter Your Question"
                      placeholder="What has been the highlight of your past role?"
                      value={addQuestions.currentQuestion.text}
                      onChange={(e) => {setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, text: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '25px'}}>
                    <TextField
                      label={
                        <>
                          <div className="d-flex">
                            Number of Follow Up Questions
                            <TooltipCustom
                              title={<>
                                  <p className="m-auto">Wayfaster assessments are fully dynamic, meaning follow up questions are generated based on what the candidate says.
                                  <span style={{ color: '#20C58A'}}> Watch a small demo here to see how it works</span>.
                                  </p>
                                </>}
                              style={{ marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                            </TooltipCustom>
                          </div>
                        </>
                      }
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      InputLabelProps={{
                        style: {
                          left: '10px', // Adjusts the horizontal position
                          top: '-5px', // Adjusts the vertical position
                          color: '#656565', // Sets the color of the label
                          position: 'absolute' // Ensures the label can be freely moved
                        }
                      }}
                      type="number"
                      variant="standard"
                      fullWidth={true}
                      value={addQuestions.currentQuestion.numFollowUps}
                      onChange={(e) => {
                        setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, numFollowUps: e.target.value }});
                      }}
                    />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%', marginTop: '-16px'}}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                    <Button className="btn-main gray outlined"
                      style={{ flex: 1, marginRight: '12px' }}
                      onClick={() => cancelShowAddModal('question')}>
                        Cancel
                    </Button>
                    <Button className="btn-main"
                      style={{ flex: 1 }}
                      onClick={() => saveAddQuestion()}>
                        Save
                    </Button>
                  </div>
              </DialogActions>
          </Dialog>
        </div>
        <div className="d-flex flex-col" style={{ flex: 1, border: '1px solid lightgray', borderTop: 'none', padding: '24px', overflow: 'auto' }}>
          <h3 className="section-heading" style={{ marginTop: 0 }}>
            Simulated interview preview
          </h3>
          
          <p className="color-gray" style={{ marginTop: 0, marginBottom: '32px', fontSize: '14px' }}>The simulated interview below outlines what a potential interaction might look like, based on the topic and depth you've selected/created.</p>
                
          <SimulatedPreview
            loading={loadingPreview}
            preview={interviewPreview}
          ></SimulatedPreview>
        </div>
      </DndContext>
    )
  }

  const saveAddStructuredOutput = () => {
    let msgs = []
    if (!addOutputs.currentOutput.extraction_instructions || !addOutputs.currentOutput.data_type || !addOutputs.currentOutput.key) {
      msgs.push('All fields must be filled in');
    }
    const isDuplicate = addOutputs.outputs.some(output => 
      output.key === addOutputs.currentOutput.key
    );
    if (isDuplicate) {
      msgs.push('Key already exists for another structured output');
    }

    if(msgs.length > 0 ) {
      setErrMsgs(msgs)
      return
    } else {
      setErrMsgs([])
    }

    if(addOutputs.editIndex > -1) {
      let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
      tmpOutputs[addOutputs.editIndex] = {
        extraction_instructions: addOutputs.currentOutput.extraction_instructions,
        data_type: addOutputs.currentOutput.data_type,
        key: addOutputs.currentOutput.key
      }
      setAddOutputs({
        ...addOutputs,
        outputs: tmpOutputs,
        currentOutput: { extraction_instructions: '', data_type: '', key: '' },
        editIndex: -1
      })
    } else {
      setAddOutputs({ ...addOutputs, outputs: [...addOutputs.outputs, {
        extraction_instructions: addOutputs.currentOutput.extraction_instructions,
        data_type: addOutputs.currentOutput.data_type,
        key: addOutputs.currentOutput.key
      }], currentOutput: { extraction_instructions: '', data_type: '', key: '' }})
    }
    setShowAddOutputInput(false)
  }

  const renderAddOutputs = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
          {/* Structured Outputs Display */}
          <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Structured Outputs</h3>
            
              <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                onClick={() => {triggerShowAddModal('output')}}>
                <Add className="my-auto" fontSize="24px"/>
                Add Output
              </button>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>Extract some set of structured information out of the interview.</p>
            <Grid container>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: 0, margin: 0, width: '65%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Key
                      </TableCell>
                      <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Description
                      </TableCell>
                      <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Type
                      </TableCell>
                      <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addOutputs.outputs.map((output, idx) => {
                      return (
                        <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.key}</TableCell>
                          <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.extraction_instructions}</TableCell>
                          <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.data_type}</TableCell>
                          <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                              <IconButton
                                onClick={() => {
                                  setAddOutputs({
                                    ...addOutputs,
                                    currentOutput: {
                                      key: output.key,
                                      data_type: output.data_type,
                                      extraction_instructions: output.extraction_instructions
                                    },
                                    editIndex: idx,
                                  })
                                  setShowAddOutputInput(true)
                                }}
                                style={{ color: '#656565', marginRight: '4px' }}>
                                  <img src={EditIconSvg} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
                                  console.log(tmpOutputs)
                                  tmpOutputs.splice(idx, 1)
                                  console.log(tmpOutputs)
                                  setAddOutputs({ ...addOutputs, outputs: tmpOutputs })
                                }}
                                style={{ color: '#656565'}}>
                                  <img src={DeleteIconSvg} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>

          {/* Add Outputs */}
          <Dialog open={showAddOutputInput}>
              <DialogTitle>Add Structured Output</DialogTitle>
              <DialogContent>
                <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>
                  Extract some set of structured information out of the interview
                </p>
                <Grid container>
                  <Grid item xs={2} className="d-flex left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Key</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '14px',
                          margin: 'auto'
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      fullWidth={true}
                      placeholder="past_employers"
                      value={addOutputs.currentOutput.key}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, key: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Extraction Instructions</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '14px',
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '14px !important', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      fullWidth={true}
                      placeholder="Get a list of companies the person has worked at"
                      value={addOutputs.currentOutput.extraction_instructions}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, extraction_instructions: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Type</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <Select
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '13px',
                          border: '2px solid lightgray',
                          borderRadius: '6px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid lightgray',
                          borderRadius: '6px'
                        },
                      }}
                      fullWidth={true}
                      value={addOutputs.currentOutput.data_type}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, data_type: e.target.value }})}}
                    >
                      <MenuItem value="List">List</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex flex-col" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                    <Button className="btn-main gray outlined"
                      style={{ flex: 1, marginRight: '12px' }}
                      onClick={() => {
                        setErrMsgs([])
                        cancelShowAddModal('output')
                      }}>
                        Cancel
                    </Button>
                    <Button className="btn-main"
                      style={{ flex: 1 }}
                      onClick={() => saveAddStructuredOutput()}>
                        Save
                    </Button>
                  </div>
              </DialogActions>
          </Dialog>
      </div>
    )
  }

  const saveAddScoring = () => {
    let msgs = []

    // VALIDATION LOGIC
    if (addScoring.currentSignal.signal == "") {
      msgs.push('Skill name must be filled in.')
    }
    if (addScoring.currentSignal.weight <= 0) {
      msgs.push('Weight must be greater than 0%')
    }
    if (addScoring.currentSignal.weight > 100) {
      msgs.push('Weight must not be greater than 100%')
    }

    if(msgs.length > 0 ) {
      setErrMsgs(msgs)
    } else {
      setErrMsgs([])
      
      // get criteria
      let criteria = {}
      for (let i = 0; i < Number(addScoring.currentSignal.scale); i++) {
        criteria[`${i + 1}PointCriteria`] = addScoring.currentSignal[`${i + 1}PointCriteria`];
      }
      

      if(addScoring.currentSignal.editIndex >= 0) {
        let updatedSignals = [...addScoring.signals];

        updatedSignals[addScoring.currentSignal.editIndex] = {
          signal: addScoring.currentSignal.signal,
          scale: addScoring.currentSignal.scale,
          ...criteria,
          weight: addScoring.currentSignal.weight,
        }
        setAddScoring({ signals: updatedSignals, currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': '' }})
      } else {
        setAddScoring({ signals: [...addScoring.signals, {
          signal: addScoring.currentSignal.signal,
          scale: addScoring.currentSignal.scale,
          ...criteria,
          weight: addScoring.currentSignal.weight
        }], currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
      }
      cancelShowAddModal('signal')
      cancelShowAddModal('signal-assist')
    }
  }

  const [scoringGenerating, setScoringGenerating] = useState(false);
  const [scoringAssistStep, setScoringAssistStep] = useState(0);

  const [scoringAddTab, setScoringAddTab] = useState(0);
  const [scoringActiveCriteria, setScoringActiveCriteria] = useState('1PointCriteria');

  const [scoringScale, setScoringScale] = useState(4);
  
  const handleScoringAddTabChange = (event, newValue) => {
    // out of bounds, greater than previous scale
    let criteriaLabel = `${newValue + 1}PointCriteria`;
    if (!(criteriaLabel in addScoring.currentSignal)) {
      setAddScoring({
        ...addScoring,
        currentSignal: {
          ...addScoring.currentSignal,
          [criteriaLabel]: "", // init new criteria
        },
      });
    }
    setScoringAddTab(newValue);
  }

  // returns a normalized criteria set based on all generated criteria
  const normalizeCriteria = (generatedCriteria, scale) => {
    switch (scale) {
      case 2:
        // Poor, Excellent  
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['6PointCriteria']
        }
      
      case 3:
        // Poor, Average, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['3PointCriteria'],
          '3PointCriteria': generatedCriteria['6PointCriteria']
        }
          
      case 4:
        // Poor, Fair, Good, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['2PointCriteria'],
          '3PointCriteria': generatedCriteria['4PointCriteria'],
          '4PointCriteria': generatedCriteria['6PointCriteria']
        }
        
      case 5:
        // Poor, Fair, Average, Good, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['2PointCriteria'],
          '3PointCriteria': generatedCriteria['3PointCriteria'],
          '4PointCriteria': generatedCriteria['4PointCriteria'],
          '5PointCriteria': generatedCriteria['6PointCriteria']
        }
        
      case 6:
        // Poor, Fair, Average, Good, Very Good, Excellent
        return generatedCriteria;
        
      default:
        // Poor, Fair, Average, Good, Very Good, Excellent
        return generatedCriteria;
    }
  }

  const handleGenerateScoringCriteria = ({ context, scale, signal }) => {
    setScoringGenerating(true);
    api.generateScoringCriteria({
      context,
      signal,
      scale,
      title: roleBackground.interviewTitle,
      job_title: roleBackground.jobTitle
    })
      .then((response) => {
        let data = response.data;
        let criteria = {}

        for (let i = 0; i < Number(data.scale); i++) {
          let point = data.criteria[`${i + 1}PointCriteria`]
          criteria[`${i + 1}PointCriteria`] = ""
          for (const [key, value] of Object.entries(point)) {
            criteria[`${i + 1}PointCriteria`] += `• ${key}: ${value}\n`;
          }
        }
        setGeneratedCriteria({
          ...criteria
        });

        setAddScoring({
          ...addScoring,
          currentSignal: {
            signal: data.signal,
            weight: 0,
            scale: data.scale,
            ...normalizeCriteria(criteria, scale),
            description: context
          },
        })
        setScoringActiveCriteria('1PointCriteria');
        setScoringAssistStep((prev) => prev + 1);
        setScoringGenerating(false);
      })
      .catch((error) => {
        console.log(error)
        setScoringGenerating(false);
      })
  }

  const getScoringPlaceholder = () => {
    let placeholder = "Criteria for "
    switch (scoringActiveCriteria) {
      case '6PointCriteria':
        return placeholder + `"${getScoringDescriptor(6)}"` + ' score (6)'
      case '5PointCriteria':
        return placeholder + `"${getScoringDescriptor(5)}"` + ' score (5)'
      case '4PointCriteria':
        return placeholder + `"${getScoringDescriptor(4)}"` + ' score (4)'
      case '3PointCriteria':
        return placeholder + `"${getScoringDescriptor(3)}"` + ' score (3)'
      case '2PointCriteria':
        return placeholder + `"${getScoringDescriptor(2)}"` + ' score (2)'
      case '1PointCriteria':
        return placeholder + `"${getScoringDescriptor(1)}"` + ' score (1)'
      default:
        return null
    }
  }

  const getScoringDescriptor = (i) => {
    let scale = Number(addScoring.currentSignal.scale);

    // min / max value base cases
    if (i == 1) return "Poor";
    if (i == scale || i == SCORE_SCALES[SCORE_SCALES.length - 1]) return "Excellent"

    switch (scale) {
      case 3:
        if (i == 2) return "Fair";
      case 4:
        if (i == 2) return "Fair";
        if (i == 3) return "Good";
      case 5:
        if (i == 2) return "Fair";
        if (i == 3) return "Average";
        if (i == 4) return "Good";
      case 6:
        if (i == 2) return "Fair";
        if (i == 3) return "Average";
        if (i == 4) return "Good";
        if (i == 5) return "Very Good";
    }
  }

  const SCORE_SCALES = [2, 3, 4, 5, 6]

  const renderAddScoring = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
        {/* Scoring Display */}
        <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid lightgray' }}>
          <div className="d-flex flex-row">
            <h3 className="my-auto">Scoring</h3>
          
            <div className="d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
              <button className="btn-main assistant d-flex" style={{ marginRight: '16px' }}
                onClick={() => {triggerShowAddModal('signal-assist')}}>
                <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                Scoring Assistant
              </button>
              <button className="btn-main d-flex"
                onClick={() => {
                  setAddScoring({ ...addScoring,
                    currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
                  setScoringAddTab(0);
                  setScoringActiveCriteria('1PointCriteria')
                  triggerShowAddModal('signal');
                  }}>
                <Add className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                Add Signal
              </button>
            </div>
          </div>
          <p className="color-gray" style={{ fontSize: 14 }}>Determine what the candidate should be graded on in the interview.</p>
          <Grid container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                      Signal
                    </TableCell>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                      Weight (%)
                    </TableCell>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addScoring.signals.map((signal, idx) => {
                    return (
                      <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.signal}</TableCell>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.weight}</TableCell>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                          <IconButton
                              onClick={() => {
                                let criteria = {}
                                for (let i = 0; i < Number(signal.scale); i++) {
                                  criteria[`${i + 1}PointCriteria`] = signal[`${i + 1}PointCriteria`];
                                }
                                
                                setAddScoring({
                                  ...addScoring,
                                  currentSignal: {
                                    signal: signal.signal,
                                    ...criteria,
                                    scale: signal.scale,
                                    weight: signal.weight,
                                    editIndex: idx,
                                  },
                                })
                                setShowAddSignalInput(true)
                              }}
                              style={{ color: '#656565', marginRight: '4px' }}><img src={EditIconSvg} />
                            </IconButton>
                          <IconButton
                            onClick={() => {
                              let newSignals = addScoring.signals.filter((signal, signalIdx) => signalIdx !== idx);
                              setAddScoring({ ...addScoring, signals: newSignals });
                            }}
                            style={{color: '#656565' }}><img src={DeleteIconSvg} /></IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>

        {/* Add Scoring */}
        <Dialog open={showAddSignalInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                Add scoring expectations
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                </Grid>

                  
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={addScoring.currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={addScoring.currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                  />

              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div className="d-flex" style={{ width: '100%' }}>
              {errMsgs.length > 0 &&
                errMsgs.map((errMsg) => {
                  return (
                    <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                  )
                })
              }
            </div>
            <DialogActions>
                <div className="d-flex flex-row" style={{ width: '100%', padding: '16px'}}>
                  <Button className="btn-main gray outlined"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => cancelShowAddModal('signal')}>
                      Cancel
                  </Button>
                  <Button className="btn-main"
                    style={{ marginLeft: '12px' }}
                    onClick={() => saveAddScoring()}>
                      Save
                  </Button>
                </div>
            </DialogActions>
        </Dialog>

        
        {/* Add Signal Assist */}
        <Dialog open={showAddSignalAssistInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                {scoringAssistStep === 0 ? 
                'Generate score outputs with scoring assistant' 
                : 'Generated expectations from candidate'
                }
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              { scoringAssistStep === 0 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                  <FormField
                      label="Describe what is most important to you in this signal."
                      multiline
                      minRows={8}
                      placeholder="e.g. It is very important to me that the candidate is fluent in English, has good sentence construction and understands what I say to him/her"
                      value={addScoring.currentSignal.description}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, description: e.target.value }})}}
                      />
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
              </Grid>}
              
              { scoringAssistStep === 1 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                </Grid>
               
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={addScoring.currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={addScoring.currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                  />
              </Grid>}
                
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row" style={{ width: '100%', padding: '16px' }}>
                <Button className="btn-main gray outlined"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {cancelShowAddModal('signal-assist')
                    setErrMsgs([])}}>
                    Cancel
                </Button>
                {scoringAssistStep > 0 && <Button className="btn-main gray outlined"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {setScoringAssistStep((prev) => prev - 1 )
                    setErrMsgs([])}}>
                    Back
                </Button>}
                {scoringAssistStep === 0 && <Button className="btn-main assistant"
                  disabled={
                    scoringGenerating || (addScoring.currentSignal.signal == "" || addScoring.currentSignal.description == "")
                  }
                  style={{ marginLeft: '12px' }}
                  onClick={() => handleGenerateScoringCriteria({
                    signal: addScoring.currentSignal.signal,
                    scale: addScoring.currentSignal.scale,
                    context: addScoring.currentSignal.description
                  })}>
                    { scoringGenerating ? 
                    <>
                      Generating...
                      &nbsp;&nbsp;
                      <CircularProgress
                        className="m-auto"
                        size={14}
                        style={{ color: "white", marginLeft: "8px" }}
                        />
                    </>
                    : <>
                    <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                    Generate
                    </> }
                </Button>}
                
                {scoringAssistStep === 1 && <Button className="btn-main"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {saveAddScoring()}}>
                    Save
                </Button>}
              </div>
            </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderReviewTest = () => {
    const question_sections = groupBy(addQuestions.questions, 'section')
    // show sections only if at least 2 different sections specified
    const showSections = Object.keys(question_sections).length > 1;

    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px', marginBottom: '40px' }}>
        <Paper style={{ minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '8px', border: '1px solid #D9D9D9' }}>
        <h3 className="section-heading" style={{ marginTop: 0 }}>Review Interview</h3>
        <p className="color-gray" style={{ fontSize: 14, marginTop: 0, marginBottom: '24px' }}>Review details about the interview. You can make changes to this at any time after saving.</p>

        <div className="d-flex flex-col">
          <FormEditable
            style={{ marginBottom: '24px' }}
            label="Interview Title"
            placeholder={roleBackground.interviewTitle}
            value={roleBackground.interviewTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}>
          </FormEditable>

          <FormEditable
            style={{ marginBottom: '24px' }}
            label="Job Title"
            placeholder={roleBackground.jobTitle}
            value={roleBackground.jobTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}>
          </FormEditable>

          {/* Questions */}
          <h5 className="d-flex input-label">
            Questions 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(1)}></DriveFileRenameOutlineIcon>
          </h5>
          <div className="card" style={{ marginBottom: '24px' }}>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow sx={{
                            '& .MuiTableCell-root': {
                              lineHeight: '20px',
                              backgroundColor: '#FAFAFA',
                              color: 'var(--color-heading)',
                              padding: '12px 16px',
                              borderRadius: '8px'
                            }
                          }}>
                      <TableCell style={{ width: '70%' }}>
                        Question
                      </TableCell>
                      <TableCell style={{ minWidth: '72px' }}>
                        Follow Ups
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(question_sections).sort(sectionSort).map((keyName, keyIndex) => (
                      <SortableContext
                        items={question_sections[keyName].map((question, idx) => {return question.id })}
                        strategy={verticalListSortingStrategy}
                      >
                        {showSections && keyName != 'undefined' && <TableSection label={keyName}></TableSection>}
                        {question_sections[keyName].map((question, idx) => (
                          <>
                            <TableRow
                              sx={{
                                position: 'relative',
                                border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                            >
                              <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                                {question.question}
                              </TableCell>
                              <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{question.follow_ups}</TableCell>
                            </TableRow>
                          </>
                        ))}
                      </SortableContext>
                    ))
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>

          
          {/* Scorecard */}
          <h5 className="d-flex input-label">
            Scorecard 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(2)}></DriveFileRenameOutlineIcon>
          </h5>
          <div className="card" style={{ marginBottom: '24px' }}>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow sx={{
                            '& .MuiTableCell-root': {
                              lineHeight: '20px',
                              backgroundColor: '#FAFAFA',
                              color: 'var(--color-heading)',
                              padding: '12px 16px',
                              borderRadius: '8px'
                            }
                          }}>
                      <TableCell style={{ width: '70%' }}>
                        Name
                      </TableCell>
                      <TableCell style={{ minWidth: '72px' }}>
                        Weight
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addScoring.signals.map((signal, idx) => (
                      <>
                        <TableRow
                          sx={{
                            position: 'relative',
                            border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                        >
                          <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                            {signal.signal}
                          </TableCell>
                          <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{signal.weight}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>


          {/* Scorecard */}
          <h5 className="d-flex input-label">
            Structured Outputs 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(3)}></DriveFileRenameOutlineIcon>
          </h5>
          <div style={{ marginBottom: '24px' }}>
            {addOutputs.outputs.length > 0 ? addOutputs.outputs.map((output) => (
                  <div className="d-flex flex-col section-heading" style={{ padding: '12px', borderRadius: '8px', border: '1px solid lightgray' }}>
                      <>
                        <div className="section-heading">
                          <ChipTag variant="default" text={output.key} style={{ width: 'max-content' }} />
                        </div>
                        <div className="d-flex flex-col" style={{ marginLeft: '8px' }}>
                          <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px' }}>
                            Extraction Instructions
                          </h5>
                          <p className="section-body" style={{ fontSize: '14px' }}>
                            {output.extraction_instructions}
                          </p>

                          <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px'  }}>
                            Type
                          </h5>
                          <p className="section-body" style={{ fontSize: '14px' }}>
                            {output.data_type}
                          </p>
                        </div>
                      </>
                  </div>
                ))
              :
              <>
                <div className="d-flex flex-col section">
                  <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                    No outputs.
                  </p>
                </div>
              </>
            }
          </div>
        </div>

        <FormField
          label="Custom Interview Prompt (optional)"
          multiline
          minRows={6}
          placeholder=""
          value={reviewTest.prompt}
          onChange={(e) => {setReviewTest({ ...reviewTest, prompt: e.target.value})}}
          />

        </Paper>
      </div>
    )
  }

  const renderActiveStepComponent = () => {
    switch (activeStep) {
      case -1:
        switch (creationFlow) {
          case '':
            return renderFlowPrompt();
          case 'jd':
            return renderJDUpload();
          case 'template':
            return renderTemplateFlow();
          case 'self':
          default:
            return setActiveStep(0);
        }
      case 0:
        return renderRoleBackground();
      case 1:
        return renderAddQuestions();
      case 2:
        return renderAddScoring();
      case 3:
        return renderAddOutputs();
      case 4:
        return renderReviewTest();
      default:
        return null;
    }
  }

  return (
    <div className="d-flex flex-col" style={{ padding: 0, backgroundColor: 'white', height: '100%' }}>
      {activeStep > -1 ? <div className="d-flex flex-row" style={{ padding: '16px', borderBottom: '1px solid lightgray' }}>

        <button className="btn-main d-flex my-auto" style={{ marginRight: 'auto' }}
          onClick={() => handleBack()}>
          <NavigateBefore className="my-auto" fontSize="24px"/>
          Back
        </button>

        <Stepper activeStep={activeStep}
          className="d-flex flex-row"
          style={{ width: 'auto', margin: 'auto !important' }}
          sx={{
            ['& .MuiStep-root'] : {
              display: 'flex',
              flex: 0,
              paddingLeft: 0
            }
          }}
          alternativeLabel connector={
            <NavigateNext className="m-auto" fontSize="14px" style={{ color: 'var(--color-gray)', paddingRight: '8px' }} />
          }>
          {steps.map((label, index) => {
            const stepProps= {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel StepIconComponent={
                  () =>
                    <div className="d-flex flex-row"
                      style={{ borderRadius: '32px', border: '1px solid lightgray', padding: '6px',
                        backgroundColor: activeStep >= index ? 'black': 'white'
                        }}>
                      <Avatar className="m-auto"
                        sx={{ width: '18px', height: '18px' }}
                        style={{ backgroundColor: activeStep >= index ? 'white': 'var(--color-lightgray)',
                        margin: 0 }}
                      >
                        <p className="m-auto" style={{ fontSize: '12px', color: 'var(--color-main)'}}>{index+1}</p>
                      </Avatar>
                      <h5 style={{ fontSize: '14px', margin: 'auto 8px',
                        color: activeStep >= index ? 'white': 'var(--color-gray)' }}>{label}</h5>
                    </div>} {...labelProps}>
                  </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto' }}
          onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Create Interview' : 'Next'}
            <NavigateNext className="my-auto" fontSize="24px" />
        </button>

      </div>
      :
      <div className="d-flex flex-row" style={{ marginRight: 'auto', padding: '16px' }}>
        <button className="btn-main small d-flex gray outlined"
          onClick={() => {navigate(process.env.REACT_APP_HOME_TAB_ENABLED === "true" ? "/home" : "/interviews")}}>
          <ArrowBack fontSize="14px" className="color-heading m-auto" />
        </button>
        <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>{process.env.REACT_APP_HOME_TAB_ENABLED === "true" ? "Home" : "My Interviews"}</h5>
      </div>
      }
      {/* Error Messages Header */}
      {errMsgs.length > 0 &&
        !(showAddQuestionInput || showAddOutputInput || showAddSignalInput) &&
        errMsgs.map((errMsg) => {
          return (
            <Alert severity="error" style={{ width: '100%', margin: 'auto'}}>{errMsg}</Alert>
          )
        })
      }
      <div className="d-flex flex-row" style={{ flex: 1, maxHeight: 'calc(100vh - 1px)', overflow: 'auto' }}>
        {renderActiveStepComponent()}
      </div>
    </div>
  )
}


const getCompletedSessions = (id) => {
  return axios.post(proxyEndpoint + '/get-completed-sessions', { interviewId: id })
  .then(response => {
    console.log(response.data)
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

const getSession = (sessionId) => {
  return axios.post(proxyEndpoint + '/session', { id: sessionId }) // TODO: add session token auth
  .then(response => {
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

export const SessionDetail = ({ isPubliclyViewable }) => {
  const BASE_SCORE = 5;
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const location = useLocation();
  const { interview_id, session_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [interview, setInterview] = useState({});
  const [session, setSession] = useState({});
  const [sessionList, setSessionList] = useState([]);
  const [previousSessionId, setPreviousSessionId] = useState('');
  const [nextSessionId, setNextSessionId] = useState('');
  const [videoRecordingLink, setVideoRecordingLink] = useState('')
  const [audioRecordingLink, setAudioRecordingLink] = useState('');

  const [currentSideTab, setCurrentSideTab] = useState(0);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);

  // misc
  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)
  const [getInterviewCalled, setGetInterviewCalled] = useState(false);
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const [getSessionListCalled, setGetSessionListCalled] = useState(false);
  const [getVideoRecordingLinkCalled, setGetVideoRecordingLinkCalled] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [copied, setCopied] = useState(false)
  const [showMessages, setShowMessages] = useState(false)
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentTranscriptLanguage, setCurrentTranscriptLanguage] = useState(Language.ENGLISH)
  const [gettingTranslation, setGettingTranslation] = useState(false)
  const [currentLanguageTab, setCurrentLanguageTab] = useState(0) 
  const [showScorecard, setShowScorecard] = useState(false)
  const [currentScorecard, setCurrentScorecard] = useState([])
  const [actionsOpen, setActionsOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [loomLinkReplaceCalled, setLoomLinkReplaced] = useState(false)
  const [team, setTeam] = useState({})
  const [teams, setTeams] = useState([])

  const [activeSidebar, setActiveSidebar] = useState(false);
  const [candidate, setCandidate] = useState({});
  
  const [showEditCandidate, setShowEditCandidate] = useState(false)
  const [showSessionDeletionConfirmation, setShowSessionDeletionConfirmation] = useState(false)

  const autoFormatCandidate = () => {
    let profile = {
      first_name: session.candidate_metadata.first_name,
      given_name: session.candidate_metadata.last_name,
      interview: [
        {
          interview_title: interview.title,
          scorecard: currentScorecard,
          overall_score: sessionScore,
          interview_recording: session.videoRecordingUrl,
          audio_recording: session.audio_recording
        }
      ]
    }
    setCandidate(profile);
  }
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const actionsRef = React.useRef(null);
  const shareRef = React.useRef(null);

  useEffect(() => {
    if(isPubliclyViewable && !getInterviewCalled) {
      api.getPublicInterview({ sessionId: session_id })
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
        getSession(session_id)
        .then((response) => {
          setAudioRecordingLink(response.recording_url)
          setCurrentMessages(response.messages)
          setCurrentTranscriptLanguage(response.language)
          setCurrentScorecard(response.scorecard)
          setSession(response)
        })
        .catch((err) => {
          console.error(err)
        })
  
        if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
          loom.linkReplace('.loom-video')
          setLoomLinkReplaced(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })

      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
        console.log(response.data.url)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });      
      return
    }

    var userId = ''
    var globalOverrideUser = ''
    if(user && !getInterviewCalled) {
      userId = user.email
      const teamId = localStorage.getItem('teamId')
      globalOverrideUser = localStorage.getItem('globalOverrideUser')
      if(globalOverrideUser != null) {
        userId = globalOverrideUser
      }
      api.getInterview(interview_id, userId, teamId)
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
      })
      .catch((err) => {
        console.error(err)
      })
    }

    if(user && !getSessionCalled) {
      setGetSessionCalled(true);
      getSession(session_id)
      .then((response) => {
        setAudioRecordingLink(response.recording_url)
        setCurrentMessages(response.messages)
        setCurrentTranscriptLanguage(response.language)
        setCurrentScorecard(response.scorecard)
        setSession(response)
      })
      .catch((err) => {
        console.error(err)
        setGetSessionCalled(false);
      })

      if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
        loom.linkReplace('.loom-video')
        setLoomLinkReplaced(true)
      }
    }

    if(user && !getVideoRecordingLinkCalled) {
      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });
    }
  }, [getVideoRecordingLinkCalled, getInterviewsCalled, getInterviewCalled, interview_id, interview, user, session_id, videoRecordingLink, loomLinkReplaceCalled]);

  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const teamClicked = (teamId) => {
    if (team._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setTeam(new_team)
      navigate('/interviews')
    }
  }

  const callOverrideCandidateAction = (value) => {
    // value: 'reject' or 'accept'
    const override = {
      customerOverrideCandidateAction: value
    }
    const interview_session = Object.assign(session, override);
    return axios.post(proxyEndpoint + '/interview_sessions/' + session_id, { interview_session }) // TODO: add session token auth
    .then(response => {
      setSession(response.data);

      setSnackbarMsg(`Successfully set score to ${value} candidate`)
      setOpenSnackbar(true)

      return response.data;
    })
    .catch(err => {
      console.error(err);
    });
  }

  const navigate = useNavigate()

  const navigateToSession = (sessionId) => {
    setGetSessionCalled(false);
    setGetSessionListCalled(false);
    setGetVideoRecordingLinkCalled(false);
    setVideoRecordingLink('');
    setAudioRecordingLink('');

    navigate("/interviews/" + interview_id + "/sessions/" + sessionId);
  }

  const handleSideTabChange = (event, newValue) => {
    setCurrentSideTab(newValue);
  }

  const handleActionsToggle = () => {
    setActionsOpen((prevOpen) => !prevOpen);
  };

  const handleShareToggle = () => {
    setShareOpen((prevOpen) => !prevOpen);
  };

  const handleActionsClose = (event) => {
    if (
      actionsRef.current &&
      actionsRef.current.contains(event.target)
    ) {
      return;
    }
    setActionsOpen(false)
  }

  const handleShareClose = (event) => {
    if (
      shareRef.current &&
      shareRef.current.contains(event.target)
    ) {
      return;
    }
    setShareOpen(false)
  }

  const sessionScore = useMemo(() => {
    if (!currentScorecard || currentScorecard.length === 0) return -1;

    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }

    let weightedSum = 0;
    let totalWeight = 0;

    currentScorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    return weightedAverage.toFixed(1);
  }, [currentScorecard, session])

  const passSession = (session) => {
    if (!session.scorecard || session.scorecard.length === 0 || !session.metadata.interview_config || !currentScorecard) return -1;
    // Check if weights add up to 100, if not, set them to equal weights
    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }    
    
    let weightedSum = 0;
    let totalWeight = 0;

    session.scorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        console.log('weight', weight)
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    const avgFixed = weightedAverage.toFixed(1);
    return avgFixed >= 2.5;
  }

  const renderRubricItemCount = (scorecard) => {
    let itemCount = 0;
    if(!scorecard || scorecard === undefined) {
      return 'N / A'
    }

    scorecard.map((rubricItem) => {
      if(rubricItem.score >= 3) {
        itemCount += 1
      }
      return 0
    })

    return `${itemCount} / ${scorecard.length} (${((itemCount / scorecard.length) * 100).toFixed(2)}%)`
  }

	const renderOutputValue = (value) => {
		if(!Array.isArray(value)){
			return value;
		}

		if (value.length === 0) {
			return '[]';
		}


		const formattedItems = value.map(item => {
			if (typeof item === 'string') {
				return `'${item}'`;
			}
			return String(item);
		});

		const output = '[\n  ' + formattedItems.join(',\n  ') + '\n]';
		return output;
	}

  const renderStructuredOutputs = (structuredOutputs) => {
    if(!structuredOutputs) {
      return <>
              <div className="d-flex flex-col section">
                <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                  No outputs found yet.
                </p>
              </div>
            </>
    }

    return structuredOutputs.map((output) => {
      return (
        <div className="d-flex flex-col section" style={{ padding: '12px', borderRadius: '12px', border: '1px solid lightgray' }}>
          {Object.entries(output).map(([key, value]) => (
            <>
              <div className="section-heading">
                <ChipTag variant="default" text={key} style={{ width: 'max-content' }} />
              </div>
              <p className="section-body" style={{ fontSize: '14px' }}>
                {renderOutputValue(value)}
              </p>
            </>
            )
          )}
        </div>
      )
    });
  }

  const handleSwitchChange = (event) => {
    // Update the session.shareable_candidate state based on the switch's checked value
    session.shareable_candidate = event.target.checked;
    api.updateShareableSession({ sessionId: session._id, shareable: event.target.checked }).then(response => {      
      if (response.status === 200) {
        session.shareable_candidate = response.data.shareable;
        if (response.data.candidate_id) {
          session.candidate_id = response.data.candidate_id;
        }
        setSnackbarMsg('Shareable link updated successfully')
        setOpenSnackbar(true)
      }
    })
  }

  const handleDeleteSession = () => {
    var userId = user.email
    const globalOverrideUser = localStorage.getItem('globalOverrideUser')
    if(globalOverrideUser != null) {
      userId = globalOverrideUser
    }
    const teamId = localStorage.getItem('teamId')
    const sessionId = session_id
    
    api.deleteSession({ userId, teamId, sessionId}).then((response) => {
      if (response.data.error) {
        console.error(`Failed to delete session: ${response.data.error}`);
        return;
      } else {
        setSnackbarMsg(response.data.message);
        setOpenSnackbar(true);

        setShowSessionDeletionConfirmation(false)
        navigate('/interviews/' + interview_id);
      }
    })
    .catch(error => {
      console.error('Failed to delete session:', error);
    });
  }

  const sessionEmpty = useMemo(() => {
    // return true;
    return !session || Object.keys(session).length === 0;
  }, [session]);

  const translateTranscript = (targetLanguage) => {
    if (session.language === Language.ENGLISH && targetLanguage === Language.ENGLISH) {
      setCurrentMessages(session.messages)
      setCurrentTranscriptLanguage(Language.ENGLISH)
      return
    }

    if (session[`messages_${targetLanguage}`]) {
      setCurrentMessages(session[`messages_${targetLanguage}`])
      setCurrentTranscriptLanguage(targetLanguage)
      return
    } else {
      setGettingTranslation(true)
      api.getTranslatedTranscript({ interviewId: interview._id, sessionId: session._id, targetLanguage: targetLanguage })
      .then(response => {
        setCurrentMessages(response.data.translated_messages)
        setCurrentTranscriptLanguage(targetLanguage)
        setSession({...session, [`messages_${targetLanguage}`]: response.data.translated_messages})

        setGettingTranslation(false)
      })
      .catch(error => {
        console.error('Failed to get translated transcript:', error);
        setGettingTranslation(false)
      });
    }
  }

  const handleLanguageTabChange = (event, newValue) => {
    setCurrentLanguageTab(newValue);
  }

  return (
    <Box sx={{display: 'flex'}}>
      {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
      {/* {!isPubliclyViewable && <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar>} */}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
      {/* Session */}
      <Box style={{ background: 'white', borderRadius: 6, width: '100%' }}>
          <Box container>
            {/* Session Header */}
            <Box style={{ width: '100%', padding: '16px 24px', borderBottom: '1px solid lightgray' }}>
              <Grid item xs={12}>
                <div className="d-flex flex-row">
                  {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      <button className="btn-main small d-flex gray outlined"
                        onClick={() => {navigate("/interviews/" + interview._id)}}>
                        <ArrowBack fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>My Interviews</h5>

                      <h5 className="inactive" style={{ margin: 'auto', lineHeight: '20px' }}>/</h5>

                      {!sessionEmpty ? 
                        <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>{ interview.title }</h5> :
                        <Skeleton variant="text" width={140} height={28} style={{ margin: 'auto 8px' }}/>
                      }
                    </div>
                  }

                  {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                      <button className={`btn-main small d-flex gray outlined ${previousSessionId ? "" : "inactive"}`}
                        disabled={!previousSessionId}
                        onClick={() => {navigateToSession(previousSessionId)}}>
                        <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 className={[ previousSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Previous Session</h5>

                      <h5 className="inactive" style={{ margin: 'auto 4px', lineHeight: '20px' }}>|</h5>

                      <h5 className={[ nextSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Next Session</h5>

                      <button className={`btn-main small d-flex gray outlined ${nextSessionId ? "" : "inactive"}`}
                        disabled={!nextSessionId}
                        onClick={() => {navigateToSession(nextSessionId)}}>
                        <NavigateNext fontSize="14px" className="color-heading m-auto" />
                      </button>
                    </div>
                  }
                </div>
              </Grid>
              <Box className="d-flex flex-row" style={{ width: '100%' }}>
                <div className="d-flex flex-row" style={{ width: '100%', marginTop: '16px' }}>
                  {!sessionEmpty ? <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      {session.candidate_metadata &&
                        <>
                          <h3 className="my-auto clickable" style={{ fontSize: '20px' }} onClick={() => {
                            autoFormatCandidate();
                            setActiveSidebar(true);
                          }}>
                            {session.candidate_metadata.first_name} {session.candidate_metadata.last_name}

                          </h3>
                        </>
                      }

                      {/* Status */}
                      {session.voicemail_detected ?
                        <ChipTag variant="default" text="Voicemail" style={{ marginLeft: '8px' }} />
                      :
                      <>
                        {session.status === SessionStatus.INCOMPLETE &&
                          <ChipTag variant="in-progress" text="Incomplete" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.IN_PROGRESS &&
                          <ChipTag variant="in-progress" text="In Progress" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.NOT_STARTED &&
                          <ChipTag variant="not-started" text="Not Started" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.COMPLETED &&
                          <ChipTag variant="completed" text="Completed" style={{ marginLeft: '8px' }} />
                        }

                        {/* Decision */}
                        {(() => {
                          const style = { marginLeft: '8px' };
                          
                          if (session.overall_decision === SessionDecision.UNDECIDED) {
                            return <ChipTag variant="default" text="Pending" style={style} />;
                          }
                          
                          if (session.customerOverrideCandidateAction === 'accept' || 
                              session.overall_decision === SessionDecision.PASS) {
                            return <ChipTag variant="accept" text="Accept" style={style} />;
                          }
                          
                          if (session.customerOverrideCandidateAction === 'reject' || 
                              session.overall_decision === SessionDecision.FAIL) {
                            return <ChipTag variant="reject" text="Reject" style={style} />;
                          }
                          
                          return <ChipTag variant="default" text="Pending" style={style} />;
                        })()}

                        {/* Suspicious Activity */}
                        {session.potentialCheating &&
                          <ChipTag variant="danger" text="Suspicious Activity" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                        }
                      </>
                      }
                    </div>

                    <h5 className="color-gray" style={{ margin: '8px 0px', fontWeight: 500, fontSize: '14px' }}>
                      {session.created_at.$date ? (
                        new Date(session.created_at.$date).toLocaleDateString()
                      ) : (
                        new Date(session.created_at * 1000).toLocaleDateString()
                      )}
                    </h5>
                  </div> :
                  <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <Skeleton variant="text" width={180} height={30} />
                    <Skeleton variant="text" width={100} height={18} />
                  </div>
                  }

                  {!sessionEmpty ? <div className="d-flex flex-row my-auto" style={{ marginLeft: 'auto' }}>
                    <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Invited on</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.created_at.$date ?
                          <>
                            {new Date(session.created_at.$date).toLocaleDateString()} <br />
                            {new Date(session.created_at.$date).toLocaleTimeString()}
                          </> :
                          <>
                            {new Date(session.created_at * 1000).toLocaleDateString()} <br />
                            {new Date(session.created_at * 1000).toLocaleTimeString()}
                          </>
                        }
                      </p>
                    </div>
                    {session.status === SessionStatus.COMPLETED && (
                      <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                        <h6 className="color-gray" style={{ margin: 0 }}>Conducted on</h6>
                        <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                          {new Date(session.date_completed_unix_timestamp).toLocaleDateString()} <br />
                          {new Date(session.date_completed_unix_timestamp).toLocaleTimeString()}
                        </p>
                      </div>
                    )}
                    {(session.metadata && session.metadata.interview_config) && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Duration</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.messages && session.messages.length > 1 &&
                          <>
                          {((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) / 60).toFixed(2)}
                          </>
                        } mins
                      </p>
                    </div>}
                    {session.client_type && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Call Type</h6>
                      <p className="color-heading" style={{ margin: '8px 0 0' }}>
                        {session.client_type === 'desktop' && <ComputerIcon fontSize="16px" />}
                        {(session.client_type === 'phone' || (session.client_type === 'mobile' && session.candidate_metadata?.call_type !== 'web')) && <PhoneIcon fontSize="16px" />}
                        {(session.client_type === 'mobile' && session.candidate_metadata?.call_type === 'web') && <ComputerIcon fontSize="16px" />}
                      </p>
                    </div>}

                    {!isPubliclyViewable &&
                    <button className="btn-main d-flex gray outlined my-auto"
                      ref={shareRef}
                      style={{ margin: 'auto 16px' }}
                      onClick={handleShareToggle}>
                        <Share fontSize="14px" className="color-heading m-auto" />
                      </button>
                    }

                    {!isPubliclyViewable &&
                    <>
                    <Popper
                      open={shareOpen}
                      anchorEl={shareRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleShareClose}>
                              <MenuList
                                autoFocusItem={shareOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                sx={{
                                  ['& .MuiMenuItem-root'] : {
                                    fontSize: '14px'
                                  },
                                  ['& .MuiTypography-root'] : {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(buildUniqueSessionLink(session.unique_session_id, 'web')).then((val) => {
                                      setSnackbarMsg('Web link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <ComputerIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Web Link
                                  </ListItemText>
                                </MenuItem>
                                {session.client_type && (session.client_type === 'phone' || session.client_type === 'mobile') && <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(buildUniqueSessionLink(session.unique_session_id, 'phone')).then((val) => {
                                      setSnackbarMsg('Phone link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <PhoneIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Phone Link
                                  </ListItemText>
                                </MenuItem>}
                                <TooltipCustom
                                  title={
                                    <>
                                      <p className="m-auto">
                                        Generates a public profile to share, including candidate profile and session details.
                                      </p>
                                    </>
                                  }
                                >
                                <div className="d-flex flex-row" style={{ borderTop: '1px solid lightgray', paddingTop: '8px', marginBottom: '8px' }}>
                                  <Switch
                                      size="small"
                                      checked={session.shareable_candidate}
                                      onChange={handleSwitchChange}
                                      name="shareableCandidateSwitch"
                                      inputProps={{ 'aria-label': 'Shareable Candidate Switch' }}
                                    />
                                    <p className="my-auto" style={{ fontSize: '14px', fontFamily: 'Figtree', marginLeft: '12px'}}>
                                      Make Public
                                    </p>
                                  </div>
                                  {session.shareable_candidate && <MenuItem 
                                    onClick={() => {
                                      navigator.clipboard.writeText(window.location.origin + '/public/candidate/' + session.candidate_id).then((val) => {
                                        setSnackbarMsg('Public share link copied to clipboard successfully')
                                        setOpenSnackbar(true)
                                        handleShareClose()
                                      })
                                      .catch((err) => {console.error('could not copy')})
                                    }}>
                                    <ListItemIcon>
                                      <Share fontSize="14px"/>
                                    </ListItemIcon>
                                    <ListItemText>
                                      Share Public Link
                                    </ListItemText>
                                  </MenuItem>}
                                  {/* TODO: add edit candidate back in */}
                                  {/* {session.shareable_candidate && <MenuItem 
                                    onClick={() => {
                                      setShowEditCandidate(true)
                                    }}>
                                    <ListItemIcon>
                                      <Edit fontSize="14px"/>
                                    </ListItemIcon>
                                    <ListItemText>
                                      Edit Candidate
                                    </ListItemText>
                                  </MenuItem>} */}
                                </TooltipCustom>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>

                    {/* Edit Candidate Dialog */}
                    <Dialog open={showEditCandidate} onClose={() => setShowEditCandidate(false)}>
                      <DialogTitle>Edit Candidate Details</DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2} style={{ marginTop: '8px' }}>
                          <Grid item xs={6}>
                            <FormField
                              label="First Name"
                              value={session.candidate_metadata?.first_name || ''}
                              onChange={(e) => {
                                setSession({
                                  ...session,
                                  candidate_metadata: {
                                    ...session.candidate_metadata,
                                    first_name: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormField
                              label="Last Name"
                              value={session.candidate_metadata?.last_name || ''}
                              onChange={(e) => {
                                setSession({
                                  ...session,
                                  candidate_metadata: {
                                    ...session.candidate_metadata,
                                    last_name: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button className="btn outlined" onClick={() => setShowEditCandidate(false)}>
                          Cancel
                        </Button>
                        <Button 
                          className="btn-main"
                          onClick={() => {
                            // Save candidate details
                            let candidate = {
                              _id: session.candidate_id,
                              first_name: session.candidate_metadata?.first_name,
                              last_name: session.candidate_metadata?.last_name
                            }
                            api.updateCandidate({ candidateId: session.candidate_id, candidate: candidate })
                            .then(response => {
                              setOpenSnackbar(true);
                              setSnackbarMsg('Candidate details updated successfully');
                              setShowEditCandidate(false);
                            })
                            .catch(error => {
                              console.error('Failed to update candidate details:', error);
                            });
                          }}
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* TODO: handle click for Session Actions here (web / phone share? edit interview copy?) */}
                    <button className="btn-main d-flex gray outlined my-auto"
                        ref={actionsRef}
                        id="actions-button"
                        aria-controls={actionsOpen ? 'composition-menu' : undefined}
                        aria-expanded={actionsOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleActionsToggle}>
                      Actions
                      <ExpandMore fontSize="14px" className="color-heading m-auto"
                        style={{ marginLeft: '8px' }}/>
                    </button>

                    {/* Actions */}
                    <Popper
                      open={actionsOpen}
                      anchorEl={actionsRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleActionsClose}>
                              <MenuList
                                autoFocusItem={actionsOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                sx={{
                                  ['& .MuiMenuItem-root'] : {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <h6 style={{ margin: 'auto', padding: '4px 16px' }}>Override Score</h6>
                                <MenuItem className="d-flex flex-row" onClick={() => {
                                    callOverrideCandidateAction('accept')
                                  }} style={{ width: '100%'}}
                                  selected={session.customerOverrideCandidateAction === 'accept' || passSession(session)}>
                                  Accept
                                  <Check className="color-accept" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    callOverrideCandidateAction('reject')
                                  }} style={{ width: '100%'}}
                                  selected={!(session.customerOverrideCandidateAction === 'accept' || passSession(session))}>
                                  Reject
                                  <Clear className="color-reject" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowSessionDeletionConfirmation(true)
                                  }}
                                >
                                  <ListItemIcon>
                                    <Delete fontSize="14px" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Delete Session
                                  </ListItemText>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    
                    {/* SELF SESSION DELETION */}
                    <Dialog
                      open={showSessionDeletionConfirmation}
                      onClose={() => setShowSessionDeletionConfirmation(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Confirm Session Deletion"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this session? 
                          You and the candidate will not be able to access this session anymore.
                          {session &&
                            <p style={{ fontSize: 16, padding: '24px', border: '0.5px solid darkgray', borderRadius: '10px' }}>
                              <b>Candidate Name:</b> {session.candidate_metadata.first_name} {session.candidate_metadata.last_name}
                              <br/><br/>
                              <b style={{ marginRight: 8 }}>Status:</b> 
                                  {session.voicemail_detected ?
                                    <ChipTag variant="default" text="Voicemail" />
                                  :
                                  <>
                                    {session.status === SessionStatus.NOT_STARTED &&
                                      <ChipTag variant="not-started" text="Not Started" />
                                    }
                                    {session.status === SessionStatus.INCOMPLETE &&
                                      <ChipTag variant="in-progress" text="Incomplete" />
                                    }
                                    {session.status === SessionStatus.IN_PROGRESS &&
                                      <ChipTag variant="in-progress" text="In Progress" />
                                    }
                                    {session.status === SessionStatus.COMPLETED &&
                                      <ChipTag variant="completed" text="Completed" />
                                    }                                
                                  </>
                                  }
                              <br/><br/>
                              <b style={{ marginRight: 8 }}>Score:</b> 
                                  {!session.voicemail_detected && session.status === SessionStatus.COMPLETED ?
                                      (!session.scorecard || session.scorecard.length === 0 ? 
                                          <ChipTag variant="pending" text="N/A" /> :
                                          ((session.customerOverrideCandidateAction === 'accept' || passSession(session)) ?
                                              <ChipTag variant="accept" text="Accept" /> : 
                                              <ChipTag variant="reject" text="Reject" />))
                                      :
                                      <ChipTag variant="pending" text="Pending" />
                                  }
                                  {session.potentialCheating &&
                                    <ChipTag variant="danger" text="Suspicious Activity" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                                  }             
                            </p>
                          }
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button className="btn outlined" onClick={() => setShowSessionDeletionConfirmation(false)} color="primary">
                          Close
                        </Button>
                        <Button className="btn outlined danger" onClick={() => {
                          handleDeleteSession();
                          setShowSessionDeletionConfirmation(false);
                        }} color="primary" autoFocus>
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                    </>
                    }
                  </div> :
                  <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }}/>
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }} />
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }} />
                  </div>}
                </div>
              </Box>
            </Box>

            {/* Session Details */}
            <Box style={{ position: 'relative', height: 'calc(100vh - 140px)' }}>
              {session.status === SessionStatus.NOT_STARTED && <div className="m-auto" style={{ background: 'white', borderRadius: '8px', position: 'absolute', zIndex: 1000, left: 0, right: 0, top: '124px', height: 'fit-content', width: '352px', padding: '24px', boxShadow: '0px 1px 14px 0px #00000029'}}>
                  <h3 className="section-heading">This interview isn't completed yet</h3>
                  <p className="section color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>
                    Once the candidate has completed the interview, we will display the results here.
                  </p>

                  <button className="btn-main" style={{ width: '100%' }}
                    onClick={() => navigate("/interviews/" + interview._id)}>
                    Back to list of interview sessions
                  </button>
              </div>}
              <Box className={`d-flex flex-row ${session.status === SessionStatus.NOT_STARTED ? 'blurred non-interactive' : ''}`} style={{ height: '100%' }}>
                {/* Main Details */}
                {!sessionEmpty ? <Box className="d-flex flex-col" style={{ flex: 3, padding: '24px', overflow: 'auto' }}>
                  {/* Video / Audio */}
                  <Box className="section">
                    {/* <p style={{ fontWeight: 600 }}>Video Recording</p> */}
                    <div className="section" style={{ borderRadius: '6px', overflow: 'hidden' }}>
                      {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                        <a className="loom-video" href={session.videoRecordingUrl}></a>
                      }
                      {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                        <>
                          {(videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                            <video
                              width="100%"
                              type="video/mp4"
                              controls
                              preload="auto"
                            >
                              <source src={videoRecordingLink} type="video/mp4" />
                            </video>
                            :
                            <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9' }}>
                              <VideocamOffIcon className="m-auto" style={{ color: '#ACACAC' }}/>
                            </div>
                          }
                        </>
                      }
                    </div>
                    {audioRecordingLink && <div className="d-flex flex-col">
                      <h4 className="section-heading">Audio Recording</h4>
                      <audio controls
                        className="audio-player"
                        style={{ background: 'transparent', padding: '4px', width: '50%' }}>
                          <source src={audioRecordingLink} type="audio/mpeg" />
                      </audio>
                    </div>}
                  </Box>

                  {/* Summary */}
                  <Box className="section">
                    <h4 className="section-heading">Summary</h4>
                    {(session.summary === undefined && !session.internalOverrideReject) ? (
                      <p style={{ fontSize: '14px', margin: 0 }}>N/A</p>
                    ) : (
                      typeof session.summary === 'string' ? (
                        <p style={{ fontSize: '14px', margin: 0 }}>{session.summary}</p>
                      ) : (
                        <div>
                          {Object.entries(session.summary).map(([key, points], sectionIndex) => {
                            if (Array.isArray(points)) {
                              return (
                                <div key={sectionIndex} style={{ marginBottom: '16px' }}>
                                  <p style={{ fontSize: '14px', fontWeight: 600, marginBottom: '8px' }}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                  </p>
                                  <ul style={{ fontSize: '14px', margin: 0, paddingLeft: '20px' }}>
                                    {points.map((point, i) => (
                                      <li key={i}>{point}</li>
                                    ))}
                                  </ul>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )
                    )}
                  </Box>
                  {(session.session_specific_questions && session.session_specific_questions.questions && session.session_specific_questions.questions.length > 0) &&
                    <Box className="section">
                    <h4 className="section-heading">Session-Specific Questions</h4>
                      <ol style={{ marginLeft: '18px', fontFamily: 'PPNeueMontreal', padding: 0 }}>
                      {session.session_specific_questions.questions.map((session_specific_question, index) => {
                        return (
                          <li key={index} style={{ marginBottom: '10px', fontSize: '14px' }}>{session_specific_question.question}</li>
                        )
                      })}
                      </ol>
                    </Box>
                  }
                </Box> :
                <Box className="d-flex flex-col" style={{ flex: 3, padding: '24px', overflow: 'auto', height: '100%' }}>
                  <Skeleton variant="rectangular" width="100%" height={300} style={{ borderRadius: '12px' }}/>
                  <Skeleton variant="text" width={120} height={24} style={{ marginTop: '12px' }} />
                </Box>
                }

                {/* Side Bar (Transcript, Scorecard, Outputs) */}
                <Box className="d-flex flex-col" style={{ flex: 2, padding: '24px', borderLeft: '1px solid lightgray', overflow: 'hidden'}}>
                  {!sessionEmpty ? <>
                  <Tabs value={currentSideTab} onChange={handleSideTabChange}
                    style={{ background: 'white', marginBottom: '16px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                    <Tab label="Transcript" onClick={() => setCurrentMessages(session.messages)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    <Tab label="Scorecard" onClick={() => setCurrentScorecard(session.scorecard)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />  
                    <Tab label="Outputs" disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    <Tab label="Questions Asked" disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                  </Tabs>
                  <Box style={{ maxHeight: '70vh'}}>
                    {/* Transcript */}
                    { currentSideTab == 0 &&
                      <Box className="section" style={{ overflowY: 'auto', maxHeight: '100%', paddingRight: '30px', scrollbarColor: '#d6d6d6 white' }}>
                        {/* When interview was not conducted in English, add translation tabs */}
                        {session.language !== Language.ENGLISH && currentMessages && currentMessages.length > 0 &&
                        <div className="d-flex flex-row">
                          <TranslateIcon style={{ fontSize: '16px', lineHeight: '24px', marginTop: '5px', marginRight: '8px', color: 'var(--color-main-light)' }} />
                          <Tabs value={currentLanguageTab} onChange={handleLanguageTabChange}
                            style={{ background: 'white'}}
                            sx={{
                              '& .MuiTabs-root': {
                                padding: '4px',
                                height: '24px',
                                minHeight: '24px',
                              },
                              '& .MuiTabs-flexContainer': {
                                border: '1px solid var(--color-midgray)',
                                borderRadius: '8px',
                                width: 'fit-content',
                              },
                              '& .MuiTabs-indicator': {
                                display: 'none',
                              },
                              '& .MuiTab-root': {
                                color: "var(--color-main-light)",
                                fontSize: '12px',
                                borderRadius: '8px',
                                padding: '4px 8px',
                                fontFamily: 'PPNeueMontreal',
                                textTransform: 'none',
                                height: '24px',
                                minHeight: '24px',
                              },
                              '& .MuiTab-root.Mui-selected': {
                                color: 'var(--color-main)',
                                backgroundColor: 'var(--color-primary-bg)',
                                fontWeight: 600
                              },
                            }}>
                            <Tab label={`${session.language} (Original)`} onClick={() => setCurrentMessages(session.messages)} disableRipple />
                            <Tab label={<>
                              <div className="d-flex flex-row">
                              {gettingTranslation ? 'Translating...' : Language.ENGLISH} 
                              {gettingTranslation && <CircularProgress className="my-auto" size={10} style={{ marginLeft: '6px', color: 'var(--color-main)' }} />}
                              </div>
                            </>} onClick={() => translateTranscript(Language.ENGLISH)} disabled={gettingTranslation} />  
                          </Tabs>
                        </div>}
                        {/* Transcript */}
                        {(currentMessages && currentMessages.length > 0) ? currentMessages.map((msg) => {
                          return (
                            <div className="d-flex flex-col section">
                              <h5 className="section-heading">{msg.message.role === 'assistant' ? <b>Wayfaster</b> : <b>{session.candidate_metadata ?
                                <>
                                <span style={{ lineHeight: '24px' }}>
                                  {session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name}
                                    <ChipTag className="m-auto" variant="default" text="Candidate" style={{ marginLeft: '12px', marginTop: '-2px' }} />
                                  </span>
                                </>
                                : 'Candidate' }</b>} &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="color-gray" style={{ fontSize: '12px', fontWeight: 400 }}>{new Date((msg.createdAt - currentMessages[0].createdAt)*1000).toISOString().substr(11, 8)}</span>
                              </h5>
                              <p className="section-body" style={{ fontSize: '14px' }}>{msg.message.content}</p>
                            </div>
                          )
                        }) :

                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No transcript found yet.
                            </p>
                          </div>
                        }
                      </Box>
                    }

                    {/* Scorecard */}
                    { currentSideTab == 1 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(currentScorecard && currentScorecard.length > 0) ?
                          <>
                            <div className="d-flex flex-row section" style={{ backgroundColor: getColorBgFromScore(sessionScore, DEFAULT_SCALE), width: 'auto', padding: '20px', borderRadius: "20px" }}>
                              <div className="d-flex" style={{ backgroundColor: getColorFromScore(sessionScore, DEFAULT_SCALE), height: '48px', width: '48px', borderRadius: "12px", marginRight: '16px' }}>
                                <h2 className="m-auto" style={{ fontSize: '20px', color: 'white' }}>
                                  { sessionScore }
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto">Overall Score</h5>
                            </div>

                            {currentScorecard.map((scorecardItem, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <h5 className="section-heading">{scorecardItem.signal}</h5>
                                <div className="d-flex flex-row section-heading">
                                  <h5 style={{ margin: 0, marginRight: '16px', color: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE) }}>
                                    {scorecardItem.score}/{scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE}</h5>
                                  <LinearProgress className="my-auto" variant="determinate" value={normalizeScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE)}
                                    style={{ backgroundColor: "#DADADA", height: '4px', borderRadius: '4px', width: '128px' }}
                                    sx={{
                                      [`& .MuiLinearProgress-bar`]: {
                                        backgroundColor: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE),
                                      },
                                    }} />
                                </div>
                                {session.scorecard_version === 2 ? (
                                  <div className="section-body">
                                    <p className="color-gray" style={{ fontWeight: 500, fontSize: '14px', marginBottom: '13px' }}>
                                      {scorecardItem.feedback.overall_sentiment}
                                    </p>
                                    {scorecardItem.feedback.subopinions.map((subopinion, subIndex) => (
                                      <div key={subIndex} style={{marginBottom: '16px'}}>
                                        <p style={{fontSize: '12px', marginBottom: '8px'}}>{subopinion.reasoning}</p>
                                        {subopinion.supporting_quotes_from_candidate.map((quote, quoteIndex) => (
                                          <div key={quoteIndex} style={{marginLeft: '12px', borderLeft: '2px solid #eee', paddingLeft: '12px'}}>
                                            <p style={{fontSize: '12px', fontStyle: 'italic', color: '#666'}}>
                                              "{quote.quote}"
                                              <span style={{fontSize: '11px', color: '#999'}}> - <a href="#" onClick={(e) => {e.preventDefault(); document.querySelector('.audio-player').currentTime = parseInt(quote.timestamp)}} style={{color: '#999', textDecoration: 'underline'}}>{Math.floor(parseInt(quote.timestamp)/60)}:{(parseInt(quote.timestamp)%60).toString().padStart(2,'0')}</a></span>
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="section-body color-gray" style={{ fontWeight: 500, fontSize: '12px' }}>
                                    {scorecardItem.feedback}
                                  </p>
                                )}
                              </div>
                            ))}
                          </>
                          :
                          <div className="d-flex flex-col section">
                            {session.internalOverrideReject && session.internalOverrideRejectReason ? (
                              <div className="automatic-rejection-alert">
                                <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
                                  <AlertTitle>Automatic Rejection</AlertTitle>
                                  <strong>{session.internalOverrideRejectReason}</strong>
                                </Alert>
                              </div>
                            ) : (
                              <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                                No scorecard found yet.
                              </p>
                            )}
                          </div>

                        }
                      </Box>
                    }
                    {/* Questions Completed */}
                    { currentSideTab == 3 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions) ? (
                          <>
                            <div className="d-flex flex-row section" style={{ 
                              backgroundColor: getColorBgFromScore(
                                Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                100
                              ),
                              width: 'auto', 
                              padding: '20px', 
                              borderRadius: "20px" 
                            }}>
                              <div className="d-flex" style={{ 
                                backgroundColor: getColorFromScore(
                                  Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                  100
                                ), 
                                height: '48px', 
                                width: 'auto', 
                                minWidth: '48px', 
                                borderRadius: "12px", 
                                marginRight: '16px', 
                                padding: '0 8px' 
                              }}>
                                <h2 className="m-auto" style={{ fontSize: '16px', color: 'white', whiteSpace: 'nowrap' }}>
                                  {session.parrot_text_index + 1} / {session.metadata.interview_config.questions.length}
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto">Questions Asked</h5>
                            </div>

                            {session.metadata.interview_config.questions.map((question, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <div className="d-flex flex-row align-items-center">
                                  <Checkbox 
                                    checked={index <= session.parrot_text_index} 
                                    disabled 
                                    style={{padding: '0px', marginRight: '12px'}}
                                  />
                                  <h5 className={index <= session.parrot_text_index ? 'section-heading color-main' : 'section-heading color-gray'} style={{ margin: 0 }}>
                                    {question.question}
                                  </h5>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No questions found.
                            </p>
                          </div>
                        )}
                      </Box>
                    }

                    {/* Outputs */}
                    { currentSideTab == 2 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && (session.structured_outputs || session.metadata.interview_config.found_structured_outputs)) ?
                          renderStructuredOutputs(session.structured_outputs || session.metadata.interview_config.found_structured_outputs)
                          :
                          renderStructuredOutputs(null)
                        }
                      </Box>
                    }
                  </Box>
                  </> :
                  <Box className="d-flex flex-row" style={{ marginTop: '12px', gap: '12px' }}>
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                  </Box>
                  } 
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>

      
      {/* Sidebar */}
      {activeSidebar && <div style={{ height: '100vh', width: '100vw', opacity: 0.7, background: 'black', zIndex: 5000, position: 'fixed' }}></div>}
      <div
        className={`d-flex flex-col sidebar-right ${
          activeSidebar ? "sidebar-right-show" : ""
        }`}
        style={{ padding: "12px", zIndex: 5005 }}
      >
        <CloseIcon
          className="color-gray clickable"
          style={{
            marginLeft: "auto",
            fontSize: "24px",
            position: "absolute",
            top: "12px",
            right: "12px",
          }}
          onClick={() => {
            setActiveSidebar(false);
          }}
        />
        <CandidateProfile candidate={candidate} compact={true} sessionId={session_id}></CandidateProfile>
      </div>
    </Box>
  )
}


export const Settings = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [interviews, setInterviews] = useState([])
  const [interviewerUser, setInterviewerUser] = useState(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const [refreshTeam, setRefreshTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [addTeamEmailCount, setAddTeamEmailCount] = useState(1);
  const [addTeamEmail, setAddTeamEmail] = useState('');
  const [addTeamAccess, setAddTeamAccess] = useState('full');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [inviteSent, setInviteSent] = useState(false)

  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false);

  const [teams, setTeams] = useState([]);

  const [accountOpen, setAccountOpen] = useState(false);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);
  const knitRef = useRef(null);
  // Backend call to generate & get the authSessionToken
  const newSessionFn = (e) => {
    e?.preventDefault();
    // Hit the backend to generate authSessionToken
    if(currentTeam && currentTeam._id && user) {
      fetch(proxyEndpoint + '/knit-create-session', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          originOrgId: currentTeam._id.$oid,
          originOrgName: currentTeam.name,
          originUserEmail: user.email,
          originUserName: user.name
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((r) => {
        // UI Auth component won't open with an empty session token
        if (knitRef?.current && r.msg && r.msg.token) {
          knitRef.current.setAttribute("authsessiontoken", r.msg.token);
          // Set skipIntro attribute to disable the introduction screen
          // knitRef.current.setAttribute("skipIntro",'');
        } else {
          console.error('Invalid response or missing token');
        }
      })
      .catch((err) => {
        console.error('Error in knit-create-session:', err);
      });
    }
  };  

  // Upon finishing the integration flow
  const onFinishFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
    if (currentTeam && currentTeam._id && e?.detail?.integrationDetails?.integrationId) {
      fetch(proxyEndpoint + '/update-knit-integration-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: currentTeam._id.$oid,
          integrationId: e.detail.integrationDetails.integrationId
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to update Knit integration ID');
        }
        setCurrentTeam({...currentTeam, knit_integration_id: e.detail.integrationDetails.integrationId})
        return res.json();
      })
      .catch(err => {
        console.error('Error updating Knit integration ID:', err);
      });
    }
  };

  // Upon deactivate of integration
  const onDeactivateFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
  };

  // Upon closing of the knit-auth component
  const onKnitCloseFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["knitClosed"]);
  };

  useEffect(() => {
    if(!currentTeam || !user) {
      return
    }
    
    console.log('knitRef', knitRef)
     // Assign functions to event listeners for onNewSession and onFinish events.
    knitRef.current?.addEventListener("onNewSession",newSessionFn);
    knitRef.current?.addEventListener("onFinish",onFinishFn);
    knitRef.current?.addEventListener("onDeactivate",onDeactivateFn);
    knitRef.current?.addEventListener("onKnitClose", onKnitCloseFn);
    // Set the token once the component has mounted
    newSessionFn();
    return () => {
    // Remove events listeners on unmount
      knitRef.current?.removeEventListener("onNewSession", newSessionFn);
      knitRef.current?.removeEventListener("onFinish",onFinishFn);
      knitRef.current?.removeEventListener("onDeactivate", onDeactivateFn);
      knitRef.current?.removeEventListener("onKnitClose", onKnitCloseFn);
    };
  }, [currentTeam, user]);
  
  const [showBullhornModal, setShowBullhornModal] = useState(false);
  const [bullhornClientId, setBullhornClientId] = useState('');
  const [bullhornClientSecret, setBullhornClientSecret] = useState('');
  const [bullhornUsername, setBullhornUsername] = useState('');
  const [bullhornPassword, setBullhornPassword] = useState('');
  const [bullhornInterviewLinkCustomField, setBullhornInterviewLinkCustomField] = useState('customText1');
  const [bullhornConnectionError, setBullhornConnectionError] = useState('');
  const accountRef = useRef(null);
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };
  
  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const ACCESS_OPTIONS = [
    {name: 'Full access', val: 'full'},
    // {name: 'Read only', val: 'read'}
  ]

  useEffect(() => {
    if(user && !isLoading) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      axios.post(proxyEndpoint + '/me', { userId: userId })
      .then(response => {
        setInterviewerUser(response.data);
      })
      .catch(error => {
        console.error(error);
      });

      const teamId = localStorage.getItem('teamId')
      api.getTeam({ userId, teamId }).then((response) => {
        let team = response.data;
        setCurrentTeam(team)
        setTeamMembers(team.members)
        setRefreshTeam(false);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [user, isLoading, refreshTeam]);

  useEffect(() => {
    if(!user || !isAuthenticated) {
      return
    }

    if(Object.keys(currentTeam).length > 0 && teams.length > 0) {
      return
    }

    const teamId = localStorage.getItem('teamId')
    console.log('teamId', teamId)
    if(!teamId) {
      navigate('/')
      return
    } else {
      if(Object.keys(currentTeam).length > 0) {
        return
      }
      var userId = user.email
      const overrideUser = localStorage.getItem('globalOverrideUser')
      if(overrideUser) {
        userId = overrideUser
      }

      api.getTeams({ userId: userId }).then((res) => {
        console.log('teams', res.data)
        setTeams(res.data)
      })
    }

    if(user && !getInterviewsCalled) {
      var userId = user.email
      var overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }
      api.getInterviews(userId, teamId)
      .then((response) => {
        // setInterviews(response.reverse())
        setInterviews(response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))        
        setGetInterviewsCalled(true)
      })
    }
  }, [user, isAuthenticated, currentTeam, teams, setCurrentTeam, setTeams])
  
  const toggleShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const generateApiKey = () => {
    console.log('generateApiKey')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/generate_api_key', { teamId: currentTeam._id.$oid })
      .then(response => {
        if(response.data.api_key) {
          setCurrentTeam({...currentTeam, api_key: response.data.api_key, public_team_id: response.data.public_team_id});
          setSnackbarMsg('API Key generated successfully');
          setOpenSnackbar(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const saveWebhookUrl = () => {
    console.log('saveWebhookUrl')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/update_webhook_url', { teamId: currentTeam._id.$oid, webhookUrl: currentTeam.webhook_url })
    .then(response => {
      console.log('Webhook URL saved successfully');
      if(response.data.webhook_url) {
        setCurrentTeam({...currentTeam, webhook_url: response.data.webhook_url});
        setSnackbarMsg('Team Webhook URL saved successfully');
        setOpenSnackbar(true);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  const callDeleteTeamMember = (memberId) => {
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    api.deleteTeamMember({ memberId, teamId, userId }).then((response) => {
      setSnackbarMsg(`Successfully deleted team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });

  }

  const callInviteTeamMember = (member_id, access) => {
    let memberId = member_id;
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    let role = access;
    api.inviteTeamMember({ memberId, teamId, userId, role }).then((response) => {
      setSnackbarMsg(`Successfully invited team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
      setInviteSent(true)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const teamClicked = (teamId) => {
    if (currentTeam._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setCurrentTeam(new_team)
      navigate('/interviews')
    }
  }

  return (<>
    {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
    {/* <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar> */}

    <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>
      <h3 className="section" style={{ marginTop: 0 }}>Settings</h3>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />

      {/* Teams */}

      <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          {/* Interview Questions Display */}
            <div className="d-flex flex-row section">
              <div className="d-flex flex-col">
                <h3 className="section-heading" style={{ marginTop: 0 }}>Your Team</h3>
                <p className="color-gray section" style={{ fontSize: 14, marginTop: 0 }}>Manage your existing team.</p>
              </div>
              <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto', marginRight: '16px' }}
                  onClick={() => setShowAddTeamMemberModal(true)}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Team Member
                </button>
            </div>

            <Paper style={{ margin: 'auto', width: '50%', boxShadow: 'none', borderRadius: '9px', border: '1px solid #D9D9D9' }}>
              <Grid container>
                <TableContainer style={{ width: '100%', margin: 0 }}>
                  <Table style={{ margin: 0}}>
                    <TableHead style={{ margin: 0}}>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px'
                              }
                            }}>
                        {/* <TableCell>
                          Name
                        </TableCell> */}
                        <TableCell style={{ borderRadius: '9px 0 0 0' }}>
                          Email
                        </TableCell>
                        <TableCell style={{ borderRadius: '0 9px 0 0' }}>
                          {/* Actions */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-rounded-bottom">
                    {teamMembers && teamMembers.map((member, idx) => {
                      return (
                        <TableRow key={idx} sx={{ border: 'none', borderBottom: idx == teamMembers.length - 1 ? 'none' : '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          {/* <TableCell style={{ margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            {member.name}
                          </TableCell>                           */}
                          <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{member.user_id}
                              {
                                member.status == 'invited' && <>
                                  <ChipTag variant="in-progress" text="Invited" style={{ width: 'max-content', marginLeft: '12px', marginTop: 'auto', marginBottom: 'auto' }} />
                                </>
                              }
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                              {(user && member.user_id !== user.email) ?
                                <Button className="btn-main btn-text my-auto"
                                  style={{ border: 'none !important' }}
                                  onClick={() => callDeleteTeamMember(member.user_id)}>
                                    <b style={{ color: "var(--color-reject)" }}>Delete</b>
                                </Button>
                                :
                                <>
                                </>
                              }
                              {/* <Button className="btn-main btn-text my-auto"
                                onClick={() => console.log('pressed edit')}>
                                  <b style={{ color: "var(--color-progress)" }}>Edit</b>
                              </Button> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
            {/* Add Team Dialog */}

            <Dialog open={showAddTeamMemberModal} maxWidth="md" onClose={() => {
              setAddTeamEmail('')
              setInviteSent(false)
            }}>
              <DialogContent style={{ padding: '20px 24px 0px' }}>
                <h5 className="section-heading">Invite team members
                  <TooltipCustom
                    title={<>
                        <p className="m-auto">
                          Share interviews, sessions, and other team resources with people you collaborate with.
                        </p>
                      </>}
                    style={{ fontSize: '14px', marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                  </TooltipCustom>
                </h5>
                <IconButton
                  aria-label="close"
                  onClick={() => setShowAddTeamMemberModal(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ fontSize: '16px' }} />
                </IconButton>
                <p className="color-gray" style={{ fontSize: 12, margin: 0 }}>Get your projects up and running faster by inviting your team to collaborate.</p>
                <div className="d-flex flex-row" style={{ marginTop: '16px' }}>
                  <div className="d-flex" style={{ flex: '10', marginRight: '16px' }}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineOutlined></MailOutlineOutlined></InputAdornment>,
                      }}
                      value={addTeamEmail}
                      onChange={(e) => {setAddTeamEmail(e.target.value)}}
                    />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </div>
                  <div className="d-flex" style={{ flex: '2' }}>
                    <TextField
                      select
                      variant="outlined"
                      defaultValue={'full'}
                      value={addTeamAccess}
                      onChange={(e) => {setAddTeamAccess(e.target.value)}}
                    >
                      {ACCESS_OPTIONS.map((access) =>
                        <MenuItem key={access.name} value={access.val}>
                          {access.name}
                        </MenuItem>)}
                    </TextField>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                  <div style={{ display : 'flex', width: '100%', padding: '16px'}}>
                    {/* {addTeamEmailCount < 5 && <Button className="btn-main gray btn-text"
                      style={{ marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', border: 'none !important' }}
                      onClick={() => addTeamMemberInput()}>
                        <Add style={{ fontSize: '16px' }}></Add>
                        Add another
                    </Button>} */}
                    <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
                      onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)} disabled={inviteSent}>
                        {inviteSent ?
                          <>
                            <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                            Invite Sent
                          </>
                          :
                          <>
                          <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                          Send Invite
                          </>
                        }
                    </Button>
                  </div>
              </DialogActions>
            </Dialog>
          </div>
            <Divider/>
            <h3 style={{ fontFamily: 'PPNeueMontreal', fontSize: '20px', fontWeight: 'bold' }}>Connect your ATS</h3>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Connect your Applicant Tracking System to streamline your hiring process</p>
            {process.env.REACT_APP_BULLHORN_ENABLED === "true" ? (
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>
                Note: If your team is using Bullhorn, please use the "Connect Bullhorn" button below. For all other ATS integrations, please use the "Connect other ATS" button and ensure the interview title in Wayfaster exactly matches the job name in your ATS. 
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>
                Note: For your integrations, ensure the interview title in Wayfaster exactly matches the job name in your ATS. 
              </Typography>
            )}
            {/* <Button
              variant="contained"
              color="primary"
              disabled={!isReady || (currentTeam && currentTeam.merge_account_token)}
              onClick={() => {
                
              }}
              startIcon={<LinkIcon />}
              sx={{
                fontFamily: 'PPNeueMontreal',
                fontSize: '14px',
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
                '&:disabled': {
                  backgroundColor: '#444',
                  color: '#888',
                },
                padding: '10px 20px',
                borderRadius: '8px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              {currentTeam && currentTeam.merge_account_token ? 'ATS is Connected' : 'Connect your ATS'}
            </Button> */}
            <div style={{ display: 'flex', gap: '10px' }}>
              <knit-auth ref={knitRef}>
                <Button 
                variant="contained"
                color="primary"
                startIcon={<LinkIcon />}
                disabled={currentTeam?.knit_integration_id}
                sx={{
                  fontFamily: 'PPNeueMontreal',
                  fontSize: '14px',
                  backgroundColor: 'black',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                  '&:disabled': {
                    backgroundColor: '#444',
                    color: '#888',
                  },
                  padding: '10px 20px',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}              
                slot="trigger">{currentTeam?.knit_integration_id ? 'ATS Connected' : 'Connect to ATS'}</Button>
              </knit-auth>
              {process.env.REACT_APP_BULLHORN_ENABLED === "true" && (
                <Button 
                  variant="contained"
                  color="primary"
                  startIcon={<LinkIcon />}
                  disabled={currentTeam?.bullhorn_client_id}
                  onClick={() => {
                    setShowBullhornModal(true);
                  }}
                  sx={{
                    fontFamily: 'PPNeueMontreal',
                    fontSize: '14px',
                    backgroundColor: '#ff6b00',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#cc5500',
                    },
                    '&:disabled': {
                      backgroundColor: '#ff6b0066',
                      color: '#ff6b0099',
                    },
                    padding: '10px 20px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    textTransform: 'none'
                  }}
                >
                  {currentTeam?.bullhorn_client_id ? 'Bullhorn Connected' : 'Connect to Bullhorn'}
                </Button>
              )}
              <Dialog 
                open={showBullhornModal}
                onClose={() => setShowBullhornModal(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogContent style={{ padding: '20px 24px' }}>
                  <Typography variant="h5" sx={{ fontFamily: 'PPNeueMontreal', fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>
                    Connect to Bullhorn
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowBullhornModal(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                  <TextField
                    required
                    fullWidth
                    label="Client ID"
                    margin="normal"
                    value={bullhornClientId}
                    onChange={(e) => setBullhornClientId(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="Client Secret" 
                    margin="normal"
                    value={bullhornClientSecret}
                    onChange={(e) => setBullhornClientSecret(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="API Username"
                    margin="normal" 
                    value={bullhornUsername}
                    onChange={(e) => setBullhornUsername(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="API Password"
                    type="password"
                    margin="normal"
                    value={bullhornPassword}
                    onChange={(e) => setBullhornPassword(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                  />
                  <hr style={{ margin: '24px 0', border: 'none', borderTop: '1px solid #EAECF0' }} />
                  
                  <p style={{ 
                    color: '#6B7280', 
                    fontSize: '14px', 
                    marginBottom: '16px',
                    fontFamily: 'PPNeueMontreal'
                  }}>
                    Optionally, you can customize which field we use to store Wayfaster interview links on Candidate entities below (the default is customText1).
                  </p>

                  <TextField
                    select
                    fullWidth
                    label="Interview Link Custom Field"
                    margin="normal"
                    value={bullhornInterviewLinkCustomField || 'customText1'}
                    onChange={(e) => setBullhornInterviewLinkCustomField(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'PPNeueMontreal' }
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                      <MenuItem key={num} value={`customText${num}`}>customText{num}</MenuItem>
                    ))}
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                      <MenuItem key={num+10} value={`customTextBlock${num}`}>customTextBlock{num}</MenuItem>
                    ))}
                  </TextField>
                  {bullhornConnectionError && <p style={{ color: 'red', fontFamily: 'PPNeueMontreal', fontSize: '14px', marginTop: '16px' }}>{bullhornConnectionError}</p>}
                </DialogContent>
                <DialogActions>
                  <div style={{ display: 'flex', width: '100%', padding: '16px'}}>
                    <Button 
                      className="btn-main"
                      style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px 20px', borderRadius: '8px', marginLeft: 'auto' }}
                      onClick={() => {
                        // Check if any required fields are missing
                        if (!bullhornClientId || !bullhornClientSecret || !bullhornUsername || !bullhornPassword) {
                          setBullhornConnectionError('Please fill in all required fields.');
                          return;
                        }

                        api.connectBullhorn({
                          teamId: currentTeam._id.$oid,
                          apiUsername: bullhornUsername,
                          apiPassword: bullhornPassword,
                          clientId: bullhornClientId,
                          clientSecret: bullhornClientSecret,
                          interviewLinkCustomField: bullhornInterviewLinkCustomField
                        })
                        .then(response => {
                          if (response.status === 200) {
                            setCurrentTeam({
                              ...currentTeam,
                              bullhorn_client_id: bullhornClientId,
                              bullhorn_client_secret: bullhornClientSecret,
                              bullhorn_username: bullhornUsername,
                              bullhorn_password: bullhornPassword
                            });
                            setShowBullhornModal(false);

                            setBullhornConnectionError('');
                            setBullhornClientId('');
                            setBullhornClientSecret('');
                            setBullhornUsername('');
                            setBullhornPassword('');
                            
                            setSnackbarMsg('Successfully connected to Bullhorn');
                            setOpenSnackbar(true);
                          }
                        })
                        .catch(error => {
                          setBullhornConnectionError(error.response?.data?.error || "Failed to connect to Bullhorn. Please check your credentials and try again.");
                        });
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
            {currentTeam && currentTeam.merge_account_token && (
              <div style={{ marginTop: '20px' }}>
                <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>You've connected your ATS. Set the stage name in your ATS that corresponds to the Wayfaster interview stage.</p>
                <FormField
                  label="Wayfaster Stage Name"
                  tooltip
                  tooltipTitle="Enter the name of the stage in your ATS that corresponds to Wayfaster interviews."
                  variant="outlined"
                  value={currentTeam.wayfaster_stage_name || ""}
                  onChange={(e) => {
                    setCurrentTeam({
                      ...currentTeam,
                      wayfaster_stage_name: e.target.value
                    });
                  }}
                  inputProps={{ style: { fontFamily: 'PPNeueMontreal', fontSize: '14px' } }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    api.updateWayfasterStageName({
                      userId: user.email,
                      teamId: currentTeam._id.$oid,
                      wayfasterStageName: currentTeam.wayfaster_stage_name
                    })
                    .then((res) => {
                      console.log("Wayfaster stage name updated successfully:", res);
                      setCurrentTeam({
                        ...currentTeam,
                        wayfaster_stage_name: currentTeam.wayfaster_stage_name
                      });
                      setSnackbarMsg("Wayfaster stage name updated successfully");
                      setOpenSnackbar(true);
                    })
                    .catch((err) => {
                      console.error("Failed to update Wayfaster stage name:", err);
                      setSnackbarMsg("Failed to update Wayfaster stage name");
                      setOpenSnackbar(true);
                    });
                  }}
                  sx={{
                    fontFamily: 'PPNeueMontreal',
                    fontSize: '14px',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                    padding: '10px 20px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    marginTop: '10px',
                  }}
                >
                  Save Stage Name
                </Button>
              </div>
            )}
            <br/>
            <h3>Developer</h3>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Settings for interacting with our service via our public API and webhooks.</p>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Check out our <a href="https://wayfaster.mintlify.app" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--color-main)', textDecoration: 'underline' }}>docs here</a> to see the endpoints and webhook events we support.</p>
            {currentTeam && currentTeam.public_team_id &&
              <>
                <h4>Your Team ID</h4>
                <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>This is your username for interacting with our service via our public API. This never changes and it is specific to your team.</p>            
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  value={currentTeam && currentTeam.public_team_id ? currentTeam.public_team_id : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                </div>
              </>
            }
            <h4>Your API Key</h4>
            {currentTeam && currentTeam.api_key &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  type={showApiKey ? "text" : "password"}
                  value={currentTeam && currentTeam.api_key ? currentTeam.api_key : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                <button className="btn-main outlined d-flex"
                  style={{ height: '46px', marginLeft: '8px', marginBottom: '16px' }}
                  onClick={toggleShowApiKey}>
                  {showApiKey ? <VisibilityOffIcon className="m-auto"/> : <VisibilityIcon className="m-auto"/>}
                </button>
              </div>
            }
            {(!currentTeam || !currentTeam.api_key) &&
              <p>No API Key found. Generate one.</p>
            }
            <br/>
            <button className="btn-main" onClick={generateApiKey} style={{ fontSize: '12px'}}>
              {currentTeam && currentTeam.api_key ? "Regenerate API Key" : "Generate API Key"}
            </button>
            <h4>Webhook Configuration</h4>
            <FormField
              label="Webhook URL"
              tooltip
              tooltipTitle="Enter the endpoint where we should send interview session start and completion events to. This is set for the entire team."
              variant="outlined"
              value={currentTeam && currentTeam.webhook_url ? currentTeam.webhook_url : ""}
              onChange={(e) => setCurrentTeam({...currentTeam, webhook_url: e.target.value})}
            />
            <button className="btn-main" onClick={() => saveWebhookUrl()}  style={{ fontSize: '12px'}}>
              Save
            </button>
    </div>
    
  </>
)
}

export const GettingStarted = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
      {/* <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar> */}

      <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>

        <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          <div className="d-flex flex-row section justify-content-between align-items-center">
            <div className="d-flex flex-col" style={{ flex: 1 }}>
              <h3 className="section-heading" style={{ marginTop: 0 }}>Discover how Wayfaster works</h3>
              <p className="color-gray section" style={{ fontSize: 14, marginTop: 0 }}>Take a few minutes to discover how the product works.</p>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                className="btn-main btn-dark-mode d-flex"
                onClick={() => navigate('/create-interview')}
                style={{
                  padding: '16px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'transparent',
                  border: '1px solid #3B3B3B',
                  width: '135px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '56px',
                  boxSizing: 'border-box',
                  boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
                }}
              >                
                <Add sx={{ color: '#fff', fontSize: '18px' }} />
                <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600, marginRight: '8px' }}>New Interview</span>
              </button>
            </div>
          </div>

          <div className="video-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
            {[
              { id: 1, title: "Getting Started: Creating an Interview", url: "https://www.loom.com/embed/461af952d4bd4228946c43c1238767db" },
              { id: 2, title: "Adding People to a Team", url: "https://www.loom.com/embed/a9c921960e114612be30f22268e0bc61" },
              { id: 3, title: "Creating Topics for The Interview", url: "https://www.loom.com/embed/c622212a13dd45e1a346a96199bf83ef" },
              { id: 4, title: "Creating Topics from Scratch", url: "https://www.loom.com/embed/d48a3b390adf4023a3849ba0e730a07c" },
              { id: 5, title: "Creating Scoring Criteria from Scratch", url: "https://www.loom.com/embed/13ad85032afd4740abe719017ec8ae50" },
              { id: 6, title: "Structured Outputs and How to Use Them", url: "https://www.loom.com/embed/cf98ea4b370d40349ce06680a9c1baba" },
              { id: 7, title: "Creating and Sending Interview Links", url: "https://www.loom.com/embed/6f16cb930acb4ad0a9ca5aaca8d8ff9d" },
              { id: 8, title: "Viewing and Sharing Interview Results", url: "https://www.loom.com/embed/8a68e9a2fe6e4d47adb819afbe703fc5" },
              { id: 9, title: "Modifying an Existing Interview", url: "https://www.loom.com/embed/ea15388f56114f08b4e49982dfa93ef9" },
              { id: 10, title: "Improving Your Scoring Model", url: "https://www.loom.com/embed/ead87aa28d154fa6a564de42b5a80b47" },
              { id: 11, title: "Custom Prompting/Interaction With the AI", url: "https://www.loom.com/embed/27942de0bb6c4c638b88de3491633a78" }
            ].map((video) => (
              <div key={video.id} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                  <iframe
                    src={video.url}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px'
                    }}
                  ></iframe>
                </div>
                <p style={{ 
                  marginTop: '10px', 
                  fontSize: '14px', 
                  fontWeight: 'bold', 
                  textAlign: 'left',
                  fontFamily: 'PPNeueMontreal, sans-serif'
                }}>{video.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};


export const Logout = () => {
  const { isAuthenticated, logout, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      if (!isLoading) {
        if(isAuthenticated) {
          localStorage.removeItem('teamId')
          localStorage.removeItem('globalOverrideUser')

          let returnTo = 'https://admin.techinterviewer.ai'
          if (env === 'staging') {
            returnTo = 'https://staging-admin.techinterviewer.ai'
          } else if (env === 'development') {
            returnTo = 'http://localhost:3000'
          }

          logout({
            logoutParams: {
              returnTo: returnTo
            }
          });
        } else {
          navigate('/interviews')
        }
      }
    }

    checkUser();
  }, [isAuthenticated, logout, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}

export const Login = () => {
  const { user, isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      if (!isLoading) {
        if(isAuthenticated) {
          const teamId = localStorage.getItem('teamId')
          if(teamId) {
            if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
              navigate('/home') 
            } else {
              navigate('/interviews')
            }
            return
          }

          api.getTeams({ userId: user.email }).then((response) => {
            const teams = response.data
            if (teams.length === 0) {
              navigate('/your-team')
              return
            }

            let invited_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'invited') {
                  invited_team_id = team._id.$oid
                  return
                }
              })
              return
            })

            if(invited_team_id) {
              localStorage.setItem('teamId', invited_team_id)
              navigate('/your-team')
              return
            }

            // no invites found. check if active membership on any
            let active_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'active') {
                  active_team_id = team._id.$oid
                  return
                }
              })
            })

            if(active_team_id) {
              localStorage.setItem('teamId', active_team_id)
              if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
                navigate('/home')
              } else {
                navigate('/interviews')
              }
              return
            }
          })
          .catch((error) => {
            console.log(error)
            // no teams found with user
            if (error.response.status == 400) {
              navigate('/your-team')
              return
            }
          })
        } else {
          loginWithRedirect();
        }
      }
    }

    checkUser();
  }, [isAuthenticated, loginWithRedirect, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}
