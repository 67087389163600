import React, { useCallback } from "react";
import { Switch, Checkbox, Popper, Grow, MenuList, ClickAwayListener, Select, Menu, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Chip, FormControl, Accordion, AppBar, Toolbar, IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, TextField,CircularProgress, LinearProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, TableSortLabel, Table, TableRow, TableHead, Card, CardContent, Tab, Tabs, TextareaAutosize, Divider, Skeleton } from '@mui/material';
import { useState, useEffect, useRef, useMemo } from 'react';
import Add from "@mui/icons-material/Add";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Scrollbars } from 'react-custom-scrollbars';
import FormField from "../common/FormField";
import FormSelect from "../common/FormSelect";
import { SECTIONS_OPTIONS, supportedLanguages, Language, SessionDecision } from "../../util/constants";
import { secondsToTimeLimitReadable } from "../../util/interviews";
import api from "../../api/api";
import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom'
import {
  useAuth0
} from '@auth0/auth0-react'
import CreateInterviewJD from "../../pages/CreateInterviewJD";
import CreateInterviewTemplate from "../../pages/CreateInterviewTemplate";
import CreateInterviewHome from "../../pages/CreateInterviewHome";
import LanguageSelector from "../interview/LanguageSelector";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import SimulatedPreview from "../interview/SimulatedPreview";
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormEditable from "../common/FormEditable";
import TableSection from "../table/TableSection";
import ArticleIcon from '@mui/icons-material/Article';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ArrowBack, Computer, Share, ExpandLess, NavigateBefore, NavigateNext, ExpandMore, Check, Clear, MailOutlineOutlined, ContentCopyOutlined, CloseFullscreen, OpenInFull, Edit } from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';
import EditIconSvg from '../../assets/svg/EditIcon.svg';
import EditIconNewSvg from '../../assets/svg/EditIconNew.svg';
import DeleteIconSvg from '../../assets/svg/DeleteIcon.svg';
import DeleteIconNewSvg from '../../assets/svg/DeleteIconNew.svg';
import PlayIcon from '../../assets/svg/PlayIcon.svg';
import MakeInterviewUnique from '../../assets/svg/MakeInterviewUnique.svg';
import CreateSession from '../../assets/svg/CreateSession.svg';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneIcon from '@mui/icons-material/Phone';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorIcon from '@mui/icons-material/Error';
import TranslateIcon from '@mui/icons-material/Translate';

import TooltipCustom from "../common/TooltipCustom";
import ChipTag from "../common/ChipTag";

import Switcher from "../team/Switcher";
import { Sidebar } from "../nav/Sidebar";
import CandidateProfile from "../candidate/CandidateProfile";
const LanguageDetect = require('languagedetect');
const langDetector = new LanguageDetect();

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  // console.log(rowId)

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragHandleIcon />
    </IconButton>
  )
}

const detectLanguageMatch = (question, language) => {
  const detectedLanguage = langDetector.detect(question, 2);
  // shows as
  // [ [ 'english', 0.5969230769230769 ], [ 'hungarian', 0.407948717948718 ] ]
  if (detectedLanguage.length > 0) {
    return detectedLanguage[0][0] === language.toLowerCase();
  }
  return false;
}

const DraggableRow = ({ idx, row, selected, setSelectedPreview, stateAddQuestions, setAddQuestions, setShowAddQuestionInput, language }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        <div className="d-flex flex-row my-auto" style={{ marginRight: '8px', marginLeft: '8px' }}>
          {/* { !detectLanguageMatch(row.question, language ? language : Language.ENGLISH) && 
            <TooltipCustom title={
              <>
                <p className="m-auto">
                  We've detected that this question may not be in the selected language for this interview.
                </p>
              </>
            }>
              <ErrorIcon className="my-auto" sx={{ color: 'orange', fontSize: '14px', marginRight: '8px' }} />
            </TooltipCustom>
          } */}
          {row.question}
        </div>
      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{secondsToTimeLimitReadable(row.time_limit)}</TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Preview */}
            <IconButton
              onClick={() => {
                if (!selected) setSelectedPreview(row);
              }}
              style={{ color: selected ? 'var(--color-primary)' : 'var(--color-main)', marginRight: '4px' }}
            >
              <TextSnippetIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {/* Edit */}
            <IconButton
              onClick={() => {
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    id: row.id,
                    editIndex: idx,
                    time_limit: row.time_limit,
                  },
                });
                setShowAddQuestionInput(true)
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={() => {
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}

export default function CreateInterviewV2() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [activeStep, setActiveStep] = useState(-1)
    const [skipped, setSkipped] = useState(new Set())
    const [errMsgs, setErrMsgs] = useState('')
    const [creationFlow, setCreationFlow] = useState('');
    const [sections, setSections] = useState(SECTIONS_OPTIONS)
    const [newSectionName, setNewSectionName] = useState("")
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
    const [interviewPreview, setInterviewPreview] = useState([])
  
    // "Role Background"
    const [roleBackground, setRoleBackground] = useState({
      interviewTitle: '',
      assessmentLanguage: Language.ENGLISH,
      jobTitle: '',
      interviewLength: 10
    })
  
    // "Add Questions"
    const [addQuestions, setAddQuestions] = useState({
      questions: [],
      currentQuestion: {
        text: 'What has been the highlight of your past role?',
        time_limit: 30,
        editIndex: -1,
      },
      edit: false
    })
    const [showAddQuestionInput, setShowAddQuestionInput] = useState(false)
  
    // "Add Outputs"
    const [addOutputs, setAddOutputs] = useState({
      outputs: [{ key: 'past_employers', data_type: 'List', extraction_instructions: 'get a list of past employers the candidate has worked for'}],
      currentOutput: {
        output: '',
        data_type: 'List',
        extraction_instructions: ''
      },
      editIndex: -1
    })
    const [showAddOutputInput, setShowAddOutputInput] = useState(false)
  
    // "Add Scoring"
    const [addScoring, setAddScoring] = useState({
      signals: [
        {
          "signal": "English Proficiency",
          "1PointCriteria": "\t•\tClarity: Speaks unclearly with strong accents or speech issues that significantly hinder understanding.\n\t•\tVocabulary: Uses very basic vocabulary with little to no construction-specific terms and frequent major errors.\n\t•\tComprehension: Struggles to understand even basic questions and instructions, requiring frequent repetition and clarification.\n\t•\tResponsiveness: Responds inappropriately or with significant delays, showing limited understanding of the conversation context.",
          "2PointCriteria": "\t•\tClarity: Speaks with noticeable accents or speech issues that occasionally hinder understanding.\n\t•\tVocabulary: Uses basic vocabulary with limited construction-specific terms and frequent minor errors.\n\t•\tComprehension: Understands basic questions and instructions but often needs repetition or clarification for more complex ones.\n\t•\tResponsiveness: Responds to questions with some delays or hesitations, showing a partial understanding of the conversation context.",
          "3PointCriteria": "\t•\tClarity: Speaks clearly with minor accents or speech issues that do not significantly hinder understanding.\n\t•\tVocabulary: Uses a good range of vocabulary, including some construction-specific terms, with occasional minor errors.\n\t•\tComprehension: Understands most questions and instructions with little need for repetition or clarification.\n\t•\tResponsiveness: Responds appropriately to questions and demonstrates a good understanding of the conversation context.",
          "4PointCriteria": "\t•\tClarity: Speaks very clearly and articulately, with no noticeable accent or speech impediments that hinder understanding.\n\t•\tVocabulary: Uses a wide range of vocabulary, including construction-specific terms and technical language, accurately and appropriately.\n\t•\tComprehension: Fully understands questions and instructions immediately, without needing repetition or clarification.\n\t•\tResponsiveness: Responds promptly and appropriately to questions, showing a thorough understanding of the conversation context.\n",
          "scale": 4,
          "weight": 100
        }
      ],
      currentSignal: {
        'signal': '',
        'scale': 4,
        'description': '',
        '1PointCriteria': '',
        '2PointCriteria': '',
        '3PointCriteria': '',
        '4PointCriteria': '',
        'weight': 100, // percentage,
        'editIndex': -1
      }
    })
    const [generatedCriteria, setGeneratedCriteria] = useState({});
    const [showAddSignalInput, setShowAddSignalInput] = useState(false)
    const [showAddSignalAssistInput, setShowAddSignalAssistInput] = useState(false)
  
    // "Review Test"
    const [reviewTest, setReviewTest] = useState({
      antiCheating: true,
      videoRecording: true,
      audioRecording: true,
      prompt: ''
    })
  
    const [expanded, setExpanded] = useState(false)
  
    const [fetched, setFetched] = useState(false)
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const copyFrom = urlParams.get('copy_from');
      if(!isLoading && isAuthenticated && copyFrom !== undefined && !fetched) {
        setFetched(true)
        var userId = user.email
        const teamId = localStorage.getItem('teamId')
        const overrideUserLocal = localStorage.getItem('globalOverrideUser')
        if(overrideUserLocal != null) {
          userId = overrideUserLocal
        }
        api.getInterview(copyFrom, userId, teamId)
        .then((response) => {
          console.log(response)
          let questions = response.data.questions
          questions.shift()
  
          setRoleBackground({
            assessmentLanguage: Language.ENGLISH,
            interviewTitle: response.data.title,
            jobTitle: '',
            interviewLength: 10
          })
  
          setAddQuestions({
            ...addQuestions,
            questions: questions,
          })
  
          setAddScoring({
            ...addScoring,
            signals: response.data.scorecard
          })
  
          setAddOutputs({
            ...addOutputs,
            outputs: response.data.structuredOutputs
          })
  
          setReviewTest({
            antiCheating: response.data.antiCheating,
            videoRecording: response.data.videoRecording,
            audioRecording: response.data.audioRecording,
            prompt: response.data.prompt
          })
  
          // redirect to self-create flow if questions populated from copy
          setCreationFlow('self');
          setActiveStep(0);
        })
        .catch((err) => {
          console.log(err)
        })
      }
    }, [isLoading, isAuthenticated, user, fetched, setFetched,
    addQuestions, setAddQuestions, addScoring, setAddScoring, addOutputs, setAddOutputs, setReviewTest]);
  
    const steps = ['Details', 'Questions', 'Scoring', 'Outputs', 'Review'];
    const navigate = useNavigate();
    const sensors = useSensors(
      useSensor(MouseSensor, {}),
      useSensor(TouchSensor, {}),
      useSensor(KeyboardSensor, {})
    )
  
  
    const createInterview = () => {
      if (user) {
        const interviewConfig = {
          version: 2,
          title: roleBackground.interviewTitle,
          length: roleBackground.interviewLength,
          language: roleBackground.assessmentLanguage,
          jobTitle: roleBackground.jobTitle,
          questions: addQuestions.questions,
          scorecard: addScoring.signals,
          prompt: reviewTest.prompt,
          structuredOutputs: addOutputs.outputs,
        };
  
        var userId = user.email
        const overrideUserLocal = localStorage.getItem('globalOverrideUser')
        if(overrideUserLocal != null) {
          userId = overrideUserLocal
        }
        const payload = {
          userId: userId,
          teamId: localStorage.getItem('teamId'),
          interviewConfig: interviewConfig,
        };
        api
          .createInterview(payload)
          .then((response) => {
            console.log(response.data);
            navigate("/interviews/" + response.data.id);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log('User not logged in');
      }
    };
  
    const isStepOptional = (step) => {
      return false;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const interviewDetailsValid = () => {
      let valid = true
      let msgs = []
  
      if(roleBackground.assessmentLanguage.length === 0 || roleBackground.interviewTitle.length === 0 || roleBackground.jobTitle.length === 0) {
        console.log(roleBackground)
        valid = false
        msgs.push('Please fill in all fields')
      }
  
      if(roleBackground.interviewLength < 5 || roleBackground.interviewLength > 20) {
        valid = false
        msgs.push('Max Interview length must be at least 5 minutes and no more than 20.')
      }
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const questionDetailsValid = () => {
      let valid = true
      let msgs = []
  
      if(addQuestions.questions.length === 0) {
        valid = false
        msgs.push('You must add at least one question before proceeding.')
      }
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const scoringDetailsValid = () => {
      let msgs = []
      let valid = true
  
      // check that percentages == 100
      console.log(addScoring.signals)
      let totalWeight = addScoring.signals.reduce((total, signal) => total + signal.weight, 0);
      console.log(totalWeight)
      if(addScoring.signals.length > 0 && totalWeight !== 100) {
        valid = false;
        msgs.push('The total weight of all skills must add up to 100%.');
      }
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const outputsDetailsValid = () => {
      let msgs = []
      let valid = true
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const reviewValid = () => {
      let valid = true
      let msgs = []
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      // form validation
      if(activeStep === 0) {
        // validate Interview Details
        const { isValid, msgs } = interviewDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 1) {
        // validate question details
        const { isValid, msgs } = questionDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 2) {
        // validate scoring details
        const { isValid, msgs } = scoringDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 3) {
        // validate outputs details
        const { isValid, msgs } = outputsDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 4) {
        // validate review test details
        const { isValid, msgs } = reviewValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
        createInterview();
      }
  
      setErrMsgs([])
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      if (activeStep == 0) {
        setCreationFlow('')
      }
      setErrMsgs([])
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleDragEnd = (event) => {
      const { active, over } = event;
  
      if (active.id !== over.id) {
        setAddQuestions((prevState) => {
          const oldIndex = prevState.questions.findIndex((question) => question.id === active.id);
          const newIndex = prevState.questions.findIndex((question) => question.id === over.id);
          return {
            ...prevState,
            questions: arrayMove(prevState.questions, oldIndex, newIndex),
          };
        });
      }
    };
  
    const renderFlowPrompt = () => {
      return <CreateInterviewHome setCreationFlow={setCreationFlow}/>
    }
  
    const renderJDUpload = () => {
      return <CreateInterviewJD
        setCreationFlow={setCreationFlow}
        setActiveStep={setActiveStep}
        setAddQuestions={setAddQuestions}
        setShowAddQuestionInput={setShowAddQuestionInput}
        setRoleBackground={setRoleBackground}
        roleBackground={roleBackground}
        showInterviewSetup={true}
        sx={{ width: '800px' }}
      />
    }
  
    const renderTemplateFlow = () => {
      return <CreateInterviewTemplate
        setCreationFlow={setCreationFlow}
        setActiveStep={setActiveStep}
        setAddQuestions={setAddQuestions}
        setAddScoring={setAddScoring}
        setAddOutputs={setAddOutputs}
        setShowAddQuestionInput={setShowAddQuestionInput}
        setRoleBackground={setRoleBackground}
        roleBackground={roleBackground}
        showInterviewSetup={true}
        sx={{ width: '800px' }}
      />
    }
  
    const renderRoleBackground = () => {
      return (
        <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
          <Box container className="d-flex flex-col"
            style={{ width: '60%', margin: 'auto', border: '1px solid lightgray', padding: '24px', borderRadius: '6px' }}>
            <h3 className="section" style={{ marginTop: 0 }}>Interview Details</h3>
  
            <FormField
              required
              label="Interview Title"
              tooltip
              tooltipTitle={<>
                <p className="m-auto">
                  This is the title of the interview that only you will see.
                </p>
              </>}
              placeholder="Retail Sales Associate Case Study"
              value={roleBackground.interviewTitle}
              onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}
            />
  
            <FormField
              required
              label="Job Title"
              tooltip
              tooltipTitle={<>
                <p className="m-auto">
                  This is the title of the job that will be displayed to the candidate.
                </p>
              </>}
              placeholder="Retail Sales Associate"
              value={roleBackground.jobTitle}
              onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}
              />
  
            <div className="d-flex flex-col" style={{ marginBottom: '16px' }}>
              <h5 className="input-label">Assessment Language</h5>
              <LanguageSelector currentLanguage={roleBackground.assessmentLanguage} languages={supportedLanguages} 
                clicked={(e) => {setRoleBackground({ ...roleBackground, assessmentLanguage: e })}} />
            </div>
  
            <FormField
              required
              label="Max Interview Length (minutes)"
              type="number"
              value={roleBackground.interviewLength}
              onChange={(e) => {setRoleBackground({ ...roleBackground, interviewLength: e.target.value })}}
              />
          </Box>
        </div>
      )
    }
  
    function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    }
  
    const triggerShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case 'question':
          return setShowAddQuestionInput(true);
        case 'signal':
          return setShowAddSignalInput(true);
        case 'signal-assist':
          return setShowAddSignalAssistInput(true);
        case 'output':
          return setShowAddOutputInput(true)
        default:
          return;
      }
    }
  
    const cancelShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case 'question':
          return setShowAddQuestionInput(false);
        case 'signal':
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria('1PointCriteria');
          return setShowAddSignalInput(false);
        case 'signal-assist':
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria('1PointCriteria');
          return setShowAddSignalAssistInput(false);
        case 'output':
          return setShowAddOutputInput(false)
        default:
          return;
      }
    }
  
    const saveAddQuestion = async () => {
      const questionText = addQuestions.currentQuestion.text;
      const words = questionText.split(' ');
      let msgs = []
  
      const timeLimit = parseInt(addQuestions.currentQuestion.time_limit);
      if (isNaN(timeLimit) || timeLimit < 30) {
        msgs.push('Time limit must be at least 30 seconds.');
      }
  
      if(msgs.length > 0 ) {
        setErrMsgs(msgs)
        setShowAddQuestionInput(false)
      } else {
        setErrMsgs([])
        if(addQuestions.currentQuestion.editIndex >= 0) {
          let updatedQuestions = [...addQuestions.questions];
          updatedQuestions[addQuestions.currentQuestion.editIndex] = {
            question: addQuestions.currentQuestion.text,
            time_limit: addQuestions.currentQuestion.time_limit,
            id: addQuestions.currentQuestion.id,
          }
          console.log(updatedQuestions)
          setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', time_limit: 30 }})
        } else {
          const randomId = uuidv4()
          setAddQuestions({ questions: [...addQuestions.questions, {
            question: addQuestions.currentQuestion.text,
            time_limit: addQuestions.currentQuestion.time_limit,
            id: randomId,
          }], currentQuestion: { text: 'What has been the highlight of your role?', time_limit: 30 }})
        }
        setShowAddQuestionInput(false)
        let editing = addQuestions.currentQuestion.editIndex >= 0;
        let question_id = editing ? addQuestions.currentQuestion.id : uuidv4()
        let question = {
          question: addQuestions.currentQuestion.text,
          time_limit: addQuestions.currentQuestion.time_limit,
          section: addQuestions.currentQuestion.section,
          id: editing ? addQuestions.currentQuestion.id : uuidv4(),
        }
        await updateInAddQuestions(question_id, question);
        setSelectedPreview(question)
      }
    }
  
    function groupBy(arr, property) {
      return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...acc[cur[property]] || [], cur];
        return acc;
      }, {});
    }
  
    function sectionSort(a, b) {
      const top_val = 'Introduction'
      const null_val = 'undefined'
  
      // top_val comes first
      if (a == top_val) return -1;
      if (b == top_val) return 1;
  
  
      // nulls sort after anything else
      if (a === null_val) {
        return 1;
      }
      if (b === null_val) {
          return -1;
      }
  
      // neither matches `value` - compare them normally
      return a.localeCompare(b);
    }
  
    const setSelectedPreview = async (question) => {
      setCurrentPreviewQuestion({
        topic: question.question,
        time_limit: question.time_limit
      })
  
      if (question.sample) {
        setInterviewPreview(question.sample);
      } else {
        let sample = await callGenerateConversationPreview({
          topic: question.question,
          time_limit: String(question.time_limit),
          context_type: 'question_preview',
          job_title: roleBackground.jobTitle, 
          title: roleBackground.interviewTitle
        })
        if (sample) setInterviewPreview(sample);
        else setCurrentPreviewQuestion({});
        console.log('sample', sample)
        question = {...question, sample}
        updateInAddQuestions(question.id, question)
      }
    }
  
    const updateInAddQuestions = async (rowId, updatedRow) => {
      let updatedQuestions = [...addQuestions.questions];
      let updatedIndex = updatedQuestions.findIndex((question) => question.id == rowId)
      if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedRow
      else updatedQuestions = [...updatedQuestions, updatedRow]
      setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', time_limit: 30 }})
    }
  
    const callGenerateConversationPreview = ({topic, time_limit, context_type, job_title, title }) => {
      setLoadingPreview(true);
      return api.generateConversationPreview({ topic, time_limit, context_type, job_title, title })
      .then((response) => {
        setLoadingPreview(false);
        return(response.data.sample)
      })
      .catch((error) => {
        console.log(error)
        setLoadingPreview(false);
        return null;
      })
    }
    
    const thumbVLight = ({ style, ...props }) => (
      <div style={{ ...style, backgroundColor: '#d6d6d6', borderRadius: '10px', cursor: 'pointer' }} {...props} />
    );
  
    const renderAddQuestions = () => {
      return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <div className="d-flex flex-col mx-auto" style={{ flex: 3, paddingTop: '40px', paddingBottom: '80px' }}>
            {/* Interview Questions Display */}
            <Paper className="d-flex flex-col" style={{ flex: 1, minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
              <div className="d-flex flex-row">
                <h3 className="my-auto">Interview Questions</h3>
              
                <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                    onClick={() => {triggerShowAddModal('question')}}>
                    <Add className="my-auto" fontSize="24px"/>
                    Add Question
                </button>
              </div>
              <p className="color-gray" style={{ fontSize: 14 }}>Add questions that will be asked during the interview.</p>
              <Scrollbars renderThumbVertical={thumbVLight} style={{ transition: 'all .3s ease', scrollbarColor: '#d6d6d6 white' }}>
                <Grid container>
                  <TableContainer style={{ padding: 0 , width: '100%', margin: 0}}>
                    <Table style={{ padding: 0, margin: 0, width: '100%'}}>
                      <TableHead style={{ padding: 0, margin: 0, width: '100%'}}>
                        <TableRow style={{ padding: 0, margin: 0, width: '100%'}}>
                          <TableCell style={{ padding: 0, margin: 0, width: '100%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            Question
                          </TableCell>
                          <TableCell style={{ display: 'flex', minWidth: '80px', padding: '4px', margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500, width: '72px'}}>
                            Time
                          </TableCell>
                          <TableCell style={{ fontSize: 16, color: '#656565', border: 'none'}}>
                            {/* Actions */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <SortableContext
                          items={addQuestions.questions.map((question, idx) => {return question.id })}
                          strategy={verticalListSortingStrategy}
                        >
                        {addQuestions.questions.map((question, idx) => (
                          <DraggableRow
                            idx={idx}
                            row={question}
                            selected={currentPreviewQuestion.topic == question.question}
                            setSelectedPreview={setSelectedPreview}
                            setAddQuestions={setAddQuestions}
                            setShowAddQuestionInput={setShowAddQuestionInput}
                            stateAddQuestions={addQuestions}
                            language={roleBackground.assessmentLanguage}
                          />
                        ))}
                        </SortableContext>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Scrollbars>
            </Paper>
  
            {/* Add Question */}
            <Dialog open={showAddQuestionInput}>
                <DialogTitle>Add Interview Question</DialogTitle>
                <DialogContent>
                  <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>Add questions that will be asked during the interview.</p>
                  <Grid container>
                    <Grid item xs={12} style={{ height: '215px', marginTop: '25px'}}>
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          backgroundColor: '#FEFEFE',
                          style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                        }}
                        InputLabelProps={{
                          style: {
                            padding: '5px 10px 5px 10px',
                            color: '#656565', // Sets the color of the label
                          }
                        }}
                        fullWidth={true}
                        multiline
                        minRows={8}
                        label="Enter Your Question"
                        placeholder="What has been the highlight of your past role?"
                        value={addQuestions.currentQuestion.text}
                        onChange={(e) => {setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, text: e.target.value }})}}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '25px'}}>
                      <div style={{display: 'flex', gap: '16px'}}>
                        <TextField
                          select
                          label="Time"
                          variant="standard"
                          fullWidth={true}
                          InputProps={{
                            disableUnderline: true,
                            backgroundColor: '#FEFEFE',
                            style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                          }}
                          InputLabelProps={{
                            style: {
                              left: '10px',
                              top: '-5px', 
                              color: '#656565',
                              position: 'absolute'
                            }
                          }}
                          value={addQuestions.currentQuestion.time_limit || 30}
                          onChange={(e) => {
                            setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, time_limit: e.target.value }});
                          }}
                        >
                          <MenuItem value={30}>30 seconds</MenuItem>
                          <MenuItem value={60}>1 minute</MenuItem>
                          <MenuItem value={120}>2 minutes</MenuItem>
                          <MenuItem value={300}>5 minutes</MenuItem>
                        </TextField>
                      </div>
                      {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                    </Grid>
                  </Grid>
                </DialogContent>
                {/* Error Messages */}
                <div className="d-flex" style={{ width: '100%', marginTop: '-16px'}}>
                  {errMsgs.length > 0 &&
                    errMsgs.map((errMsg) => {
                      return (
                        <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                      )
                    })
                  }
                </div>
                <DialogActions>
                    <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                      <Button className="btn-main gray outlined"
                        style={{ flex: 1, marginRight: '12px' }}
                        onClick={() => cancelShowAddModal('question')}>
                          Cancel
                      </Button>
                      <Button className="btn-main"
                        style={{ flex: 1 }}
                        onClick={() => saveAddQuestion()}>
                          Save
                      </Button>
                    </div>
                </DialogActions>
            </Dialog>
          </div>
          <div className="d-flex flex-col" style={{ flex: 1, border: '1px solid lightgray', borderTop: 'none', padding: '24px', overflow: 'auto' }}>
            <h3 className="section-heading" style={{ marginTop: 0 }}>
              Simulated interview preview
            </h3>
            
            <p className="color-gray" style={{ marginTop: 0, marginBottom: '32px', fontSize: '14px' }}>The simulated interview below outlines what a potential interaction might look like, based on the topic and depth you've selected/created.</p>
                  
            <SimulatedPreview
              loading={loadingPreview}
              preview={interviewPreview}
            ></SimulatedPreview>
          </div>
        </DndContext>
      )
    }
  
    const saveAddStructuredOutput = () => {
      let msgs = []
      if (!addOutputs.currentOutput.extraction_instructions || !addOutputs.currentOutput.data_type || !addOutputs.currentOutput.key) {
        msgs.push('All fields must be filled in');
      }
      const isDuplicate = addOutputs.outputs.some(output => 
        output.key === addOutputs.currentOutput.key
      );
      if (isDuplicate) {
        msgs.push('Key already exists for another structured output');
      }
  
      if(msgs.length > 0 ) {
        setErrMsgs(msgs)
        return
      } else {
        setErrMsgs([])
      }
  
      if(addOutputs.editIndex > -1) {
        let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
        tmpOutputs[addOutputs.editIndex] = {
          extraction_instructions: addOutputs.currentOutput.extraction_instructions,
          data_type: addOutputs.currentOutput.data_type,
          key: addOutputs.currentOutput.key
        }
        setAddOutputs({
          ...addOutputs,
          outputs: tmpOutputs,
          currentOutput: { extraction_instructions: '', data_type: '', key: '' },
          editIndex: -1
        })
      } else {
        setAddOutputs({ ...addOutputs, outputs: [...addOutputs.outputs, {
          extraction_instructions: addOutputs.currentOutput.extraction_instructions,
          data_type: addOutputs.currentOutput.data_type,
          key: addOutputs.currentOutput.key
        }], currentOutput: { extraction_instructions: '', data_type: '', key: '' }})
      }
      setShowAddOutputInput(false)
    }
  
    const renderAddOutputs = () => {
      return (
        <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
            {/* Structured Outputs Display */}
            <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
              <div className="d-flex flex-row">
                <h3 className="my-auto">Structured Outputs</h3>
              
                <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                  onClick={() => {triggerShowAddModal('output')}}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Output
                </button>
              </div>
              <p className="color-gray" style={{ fontSize: 14 }}>Extract some set of structured information out of the interview.</p>
              <Grid container>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: 0, margin: 0, width: '65%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                          Key
                        </TableCell>
                        <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                          Description
                        </TableCell>
                        <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                          Type
                        </TableCell>
                        <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addOutputs.outputs.map((output, idx) => {
                        return (
                          <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                            <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.key}</TableCell>
                            <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.extraction_instructions}</TableCell>
                            <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.data_type}</TableCell>
                            <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                                <IconButton
                                  onClick={() => {
                                    setAddOutputs({
                                      ...addOutputs,
                                      currentOutput: {
                                        key: output.key,
                                        data_type: output.data_type,
                                        extraction_instructions: output.extraction_instructions
                                      },
                                      editIndex: idx,
                                    })
                                    setShowAddOutputInput(true)
                                  }}
                                  style={{ color: '#656565', marginRight: '4px' }}>
                                    <img src={EditIconSvg} />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
                                    console.log(tmpOutputs)
                                    tmpOutputs.splice(idx, 1)
                                    console.log(tmpOutputs)
                                    setAddOutputs({ ...addOutputs, outputs: tmpOutputs })
                                  }}
                                  style={{ color: '#656565'}}>
                                    <img src={DeleteIconSvg} />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
  
            {/* Add Outputs */}
            <Dialog open={showAddOutputInput}>
                <DialogTitle>Add Structured Output</DialogTitle>
                <DialogContent>
                  <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>
                    Extract some set of structured information out of the interview
                  </p>
                  <Grid container>
                    <Grid item xs={2} className="d-flex left-label-wrapper" style={{ marginTop: '25px'}}>
                      <p style={{  fontSize: 14, fontWeight: 600 }}>Key</p>
                    </Grid>
                    <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                      <TextField
                        hiddenLabel
                        variant="outlined"
                        sx={{
                          margin: 'auto',
                          '& .MuiOutlinedInput-input': {
                            padding: '14px',
                            margin: 'auto'
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          backgroundColor: '#FEFEFE',
                          style: { border: '1px solid lightgray', borderRadius: '6px' }
                        }}
                        fullWidth={true}
                        placeholder="past_employers"
                        value={addOutputs.currentOutput.key}
                        onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, key: e.target.value }})}}
                      />
                    </Grid>
                    <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                      <p style={{  fontSize: 14, fontWeight: 600 }}>Extraction Instructions</p>
                    </Grid>
                    <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                      <TextField
                        hiddenLabel
                        variant="outlined"
                        sx={{
                          margin: 'auto',
                          '& .MuiOutlinedInput-input': {
                            padding: '14px',
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          backgroundColor: '#FEFEFE',
                          style: { padding: '14px !important', border: '1px solid lightgray', borderRadius: '6px' }
                        }}
                        fullWidth={true}
                        placeholder="Get a list of companies the person has worked at"
                        value={addOutputs.currentOutput.extraction_instructions}
                        onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, extraction_instructions: e.target.value }})}}
                      />
                    </Grid>
                    <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                      <p style={{  fontSize: 14, fontWeight: 600 }}>Type</p>
                    </Grid>
                    <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                      <Select
                        variant="outlined"
                        sx={{
                          margin: 'auto',
                          '& .MuiOutlinedInput-input': {
                            padding: '13px',
                            border: '2px solid lightgray',
                            borderRadius: '6px'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid lightgray',
                            borderRadius: '6px'
                          },
                        }}
                        fullWidth={true}
                        value={addOutputs.currentOutput.data_type}
                        onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, data_type: e.target.value }})}}
                      >
                        <MenuItem value="List">List</MenuItem>
                        <MenuItem value="Text">Text</MenuItem>
                        <MenuItem value="Number">Number</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </DialogContent>
                {/* Error Messages */}
                <div className="d-flex flex-col" style={{ width: '100%' }}>
                  {errMsgs.length > 0 &&
                    errMsgs.map((errMsg) => {
                      return (
                        <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                      )
                    })
                  }
                </div>
                <DialogActions>
                    <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                      <Button className="btn-main gray outlined"
                        style={{ flex: 1, marginRight: '12px' }}
                        onClick={() => {
                          setErrMsgs([])
                          cancelShowAddModal('output')
                        }}>
                          Cancel
                      </Button>
                      <Button className="btn-main"
                        style={{ flex: 1 }}
                        onClick={() => saveAddStructuredOutput()}>
                          Save
                      </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
      )
    }
  
    const saveAddScoring = () => {
      let msgs = []
  
      // VALIDATION LOGIC
      if (addScoring.currentSignal.signal == "") {
        msgs.push('Skill name must be filled in.')
      }
      if (addScoring.currentSignal.weight <= 0) {
        msgs.push('Weight must be greater than 0%')
      }
      if (addScoring.currentSignal.weight > 100) {
        msgs.push('Weight must not be greater than 100%')
      }
  
      if(msgs.length > 0 ) {
        setErrMsgs(msgs)
      } else {
        setErrMsgs([])
        
        // get criteria
        let criteria = {}
        for (let i = 0; i < Number(addScoring.currentSignal.scale); i++) {
          criteria[`${i + 1}PointCriteria`] = addScoring.currentSignal[`${i + 1}PointCriteria`];
        }
        
  
        if(addScoring.currentSignal.editIndex >= 0) {
          let updatedSignals = [...addScoring.signals];
  
          updatedSignals[addScoring.currentSignal.editIndex] = {
            signal: addScoring.currentSignal.signal,
            scale: addScoring.currentSignal.scale,
            ...criteria,
            weight: addScoring.currentSignal.weight,
          }
          setAddScoring({ signals: updatedSignals, currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': '' }})
        } else {
          setAddScoring({ signals: [...addScoring.signals, {
            signal: addScoring.currentSignal.signal,
            scale: addScoring.currentSignal.scale,
            ...criteria,
            weight: addScoring.currentSignal.weight
          }], currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
        }
        cancelShowAddModal('signal')
        cancelShowAddModal('signal-assist')
      }
    }
  
    const [scoringGenerating, setScoringGenerating] = useState(false);
    const [scoringAssistStep, setScoringAssistStep] = useState(0);
  
    const [scoringAddTab, setScoringAddTab] = useState(0);
    const [scoringActiveCriteria, setScoringActiveCriteria] = useState('1PointCriteria');
  
    const [scoringScale, setScoringScale] = useState(4);
    
    const handleScoringAddTabChange = (event, newValue) => {
      // out of bounds, greater than previous scale
      let criteriaLabel = `${newValue + 1}PointCriteria`;
      if (!(criteriaLabel in addScoring.currentSignal)) {
        setAddScoring({
          ...addScoring,
          currentSignal: {
            ...addScoring.currentSignal,
            [criteriaLabel]: "", // init new criteria
          },
        });
      }
      setScoringAddTab(newValue);
    }
  
    // returns a normalized criteria set based on all generated criteria
    const normalizeCriteria = (generatedCriteria, scale) => {
      switch (scale) {
        case 2:
          // Poor, Excellent  
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['6PointCriteria']
          }
        
        case 3:
          // Poor, Average, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['3PointCriteria'],
            '3PointCriteria': generatedCriteria['6PointCriteria']
          }
            
        case 4:
          // Poor, Fair, Good, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['2PointCriteria'],
            '3PointCriteria': generatedCriteria['4PointCriteria'],
            '4PointCriteria': generatedCriteria['6PointCriteria']
          }
          
        case 5:
          // Poor, Fair, Average, Good, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['2PointCriteria'],
            '3PointCriteria': generatedCriteria['3PointCriteria'],
            '4PointCriteria': generatedCriteria['4PointCriteria'],
            '5PointCriteria': generatedCriteria['6PointCriteria']
          }
          
        case 6:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;
          
        default:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;
      }
    }
  
    const handleGenerateScoringCriteria = ({ context, scale, signal }) => {
      setScoringGenerating(true);
      api.generateScoringCriteria({
        context,
        signal,
        scale,
        title: roleBackground.interviewTitle,
        job_title: roleBackground.jobTitle
      })
        .then((response) => {
          let data = response.data;
          let criteria = {}
  
          for (let i = 0; i < Number(data.scale); i++) {
            let point = data.criteria[`${i + 1}PointCriteria`]
            criteria[`${i + 1}PointCriteria`] = ""
            for (const [key, value] of Object.entries(point)) {
              criteria[`${i + 1}PointCriteria`] += `• ${key}: ${value}\n`;
            }
          }
          setGeneratedCriteria({
            ...criteria
          });
  
          setAddScoring({
            ...addScoring,
            currentSignal: {
              signal: data.signal,
              weight: 0,
              scale: data.scale,
              ...normalizeCriteria(criteria, scale),
              description: context
            },
          })
          setScoringActiveCriteria('1PointCriteria');
          setScoringAssistStep((prev) => prev + 1);
          setScoringGenerating(false);
        })
        .catch((error) => {
          console.log(error)
          setScoringGenerating(false);
        })
    }
  
    const getScoringPlaceholder = () => {
      let placeholder = "Criteria for "
      switch (scoringActiveCriteria) {
        case '6PointCriteria':
          return placeholder + `"${getScoringDescriptor(6)}"` + ' score (6)'
        case '5PointCriteria':
          return placeholder + `"${getScoringDescriptor(5)}"` + ' score (5)'
        case '4PointCriteria':
          return placeholder + `"${getScoringDescriptor(4)}"` + ' score (4)'
        case '3PointCriteria':
          return placeholder + `"${getScoringDescriptor(3)}"` + ' score (3)'
        case '2PointCriteria':
          return placeholder + `"${getScoringDescriptor(2)}"` + ' score (2)'
        case '1PointCriteria':
          return placeholder + `"${getScoringDescriptor(1)}"` + ' score (1)'
        default:
          return null
      }
    }
  
    const getScoringDescriptor = (i) => {
      let scale = Number(addScoring.currentSignal.scale);
  
      // min / max value base cases
      if (i == 1) return "Poor";
      if (i == scale || i == SCORE_SCALES[SCORE_SCALES.length - 1]) return "Excellent"
  
      switch (scale) {
        case 3:
          if (i == 2) return "Fair";
        case 4:
          if (i == 2) return "Fair";
          if (i == 3) return "Good";
        case 5:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
        case 6:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
          if (i == 5) return "Very Good";
      }
    }
  
    const SCORE_SCALES = [2, 3, 4, 5, 6]
  
    const renderAddScoring = () => {
      return (
        <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
          {/* Scoring Display */}
          <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid lightgray' }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Scoring</h3>
            
              <div className="d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                <button className="btn-main assistant d-flex" style={{ marginRight: '16px' }}
                  onClick={() => {triggerShowAddModal('signal-assist')}}>
                  <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                  Scoring Assistant
                </button>
                <button className="btn-main d-flex"
                  onClick={() => {
                    setAddScoring({ ...addScoring,
                      currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
                    setScoringAddTab(0);
                    setScoringActiveCriteria('1PointCriteria')
                    triggerShowAddModal('signal');
                    }}>
                  <Add className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                  Add Signal
                </button>
              </div>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>Determine what the candidate should be graded on in the interview.</p>
            <Grid container>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                        Signal
                      </TableCell>
                      <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                        Weight (%)
                      </TableCell>
                      <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addScoring.signals.map((signal, idx) => {
                      return (
                        <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.signal}</TableCell>
                          <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.weight}</TableCell>
                          <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                            <IconButton
                                onClick={() => {
                                  let criteria = {}
                                  for (let i = 0; i < Number(signal.scale); i++) {
                                    criteria[`${i + 1}PointCriteria`] = signal[`${i + 1}PointCriteria`];
                                  }
                                  
                                  setAddScoring({
                                    ...addScoring,
                                    currentSignal: {
                                      signal: signal.signal,
                                      ...criteria,
                                      scale: signal.scale,
                                      weight: signal.weight,
                                      editIndex: idx,
                                    },
                                  })
                                  setShowAddSignalInput(true)
                                }}
                                style={{ color: '#656565', marginRight: '4px' }}><img src={EditIconSvg} />
                              </IconButton>
                            <IconButton
                              onClick={() => {
                                let newSignals = addScoring.signals.filter((signal, signalIdx) => signalIdx !== idx);
                                setAddScoring({ ...addScoring, signals: newSignals });
                              }}
                              style={{color: '#656565' }}><img src={DeleteIconSvg} /></IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
  
          {/* Add Scoring */}
          <Dialog open={showAddSignalInput} maxWidth={'md'}>
              <DialogContent>
                <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                  Add scoring expectations
                </h3>
                <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                  Determine what the candidate should be graded on in the interview.
                </p>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: '24px' }}>
                    <FormField
                        label="What is your candidate signal called?"
                        placeholder="e.g. English proficiency"
                        value={addScoring.currentSignal.signal}
                        onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                      />
                  </Grid>
  
                    
                  <Grid item xs={2}>
                    <FormSelect
                        label="Point Scale"
                        tooltip
                        tooltipTitle={<>
                          <p className="m-auto">
                            Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                          </p>
                        </>}
                        variant="outlined"
                        value={addScoring.currentSignal.scale}
                        onChange={(e) => {
                          let scale_num = Number(e.target.value)
                          setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                          setScoringActiveCriteria('1PointCriteria')
                          setScoringAddTab(0)
                        }}
                        >
                        {SCORE_SCALES.map((score) => {
                          return <MenuItem value={score}>{score}</MenuItem>
                        })}
                        
                    </FormSelect>
                  </Grid>
                  <Grid item xs={10}>
                  <h5 className="input-label d-flex">
                    Scores
                  </h5>
                    <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                      style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                      sx={{
                        ['& .MuiTabs-indicator']: {
                          backgroundColor: 'transparent',
                        },
                        ['& .MuiTab-root']: {
                          color: "var(--color-main-light)",
                          fontSize: '14px',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          marginRight: '4px',
                          fontFamily: 'PPNeueMontreal',
                          textTransform: 'none',
                          minHeight: '40px'
                        },
                        ['& .MuiTab-root.Mui-selected']: {
                          color: 'var(--color-main)',
                          backgroundColor: 'var(--color-lightgray)',
                          fontWeight: 600
                        },
                      }}>
                        {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                          return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                        })}
                    </Tabs>
                  </Grid>
  
                  <FormField
                      label={getScoringPlaceholder()}
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Write a criteria description that the candidate has to meet to be evaluated for this score. 
                        </p>
                      </>}
                      style={{ marginBottom: '16px' }}
                      multiline
                      minRows={8}
                      placeholder={getScoringPlaceholder()}
                      value={addScoring.currentSignal[scoringActiveCriteria]}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                      />
  
                  <FormField
                      label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                      value={addScoring.currentSignal.weight}
                      type="Number"
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                    />
  
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div className="d-flex flex-row" style={{ width: '100%', padding: '16px'}}>
                    <Button className="btn-main gray outlined"
                      style={{ marginLeft: 'auto' }}
                      onClick={() => cancelShowAddModal('signal')}>
                        Cancel
                    </Button>
                    <Button className="btn-main"
                      style={{ marginLeft: '12px' }}
                      onClick={() => saveAddScoring()}>
                        Save
                    </Button>
                  </div>
              </DialogActions>
          </Dialog>
  
          
          {/* Add Signal Assist */}
          <Dialog open={showAddSignalAssistInput} maxWidth={'md'}>
              <DialogContent>
                <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                  {scoringAssistStep === 0 ? 
                  'Generate score outputs with scoring assistant' 
                  : 'Generated expectations from candidate'
                  }
                </h3>
                <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                  Determine what the candidate should be graded on in the interview.
                </p>
                { scoringAssistStep === 0 &&
                 <Grid container>
                  <Grid item xs={12} style={{ marginTop: '24px' }}>
                    <FormField
                        label="What is your candidate signal called?"
                        placeholder="e.g. English proficiency"
                        value={addScoring.currentSignal.signal}
                        onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                      />
                    <FormField
                        label="Describe what is most important to you in this signal."
                        multiline
                        minRows={8}
                        placeholder="e.g. It is very important to me that the candidate is fluent in English, has good sentence construction and understands what I say to him/her"
                        value={addScoring.currentSignal.description}
                        onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, description: e.target.value }})}}
                        />
                    <FormSelect
                        label="Point Scale"
                        tooltip
                        tooltipTitle={<>
                          <p className="m-auto">
                            Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                          </p>
                        </>}
                        variant="outlined"
                        value={addScoring.currentSignal.scale}
                        onChange={(e) => {
                          let scale_num = Number(e.target.value)
                          setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                          setScoringActiveCriteria('1PointCriteria')
                          setScoringAddTab(0)
                        }}
                        >
                        {SCORE_SCALES.map((score) => {
                          return <MenuItem value={score}>{score}</MenuItem>
                        })}
                        
                    </FormSelect>
                  </Grid>
                </Grid>}
                
                { scoringAssistStep === 1 &&
                 <Grid container>
                  <Grid item xs={12} style={{ marginTop: '24px' }}>
                    <FormField
                        label="What is your candidate signal called?"
                        placeholder="e.g. English proficiency"
                        value={addScoring.currentSignal.signal}
                        onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                      />
                  </Grid>
                 
                  <Grid item xs={2}>
                    <FormSelect
                        label="Point Scale"
                        tooltip
                        tooltipTitle={<>
                          <p className="m-auto">
                            Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                          </p>
                        </>}
                        variant="outlined"
                        value={addScoring.currentSignal.scale}
                        onChange={(e) => {
                          let scale_num = Number(e.target.value)
                          setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                          setScoringActiveCriteria('1PointCriteria')
                          setScoringAddTab(0)
                        }}
                        >
                        {SCORE_SCALES.map((score) => {
                          return <MenuItem value={score}>{score}</MenuItem>
                        })}
                        
                    </FormSelect>
                  </Grid>
                  <Grid item xs={10}>
                  <h5 className="input-label d-flex">
                    Scores
                  </h5>
                    <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                      style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                      sx={{
                        ['& .MuiTabs-indicator']: {
                          backgroundColor: 'transparent',
                        },
                        ['& .MuiTab-root']: {
                          color: "var(--color-main-light)",
                          fontSize: '14px',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          marginRight: '4px',
                          fontFamily: 'PPNeueMontreal',
                          textTransform: 'none',
                          minHeight: '40px'
                        },
                        ['& .MuiTab-root.Mui-selected']: {
                          color: 'var(--color-main)',
                          backgroundColor: 'var(--color-lightgray)',
                          fontWeight: 600
                        },
                      }}>
                        {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                          return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                        })}
                    </Tabs>
                  </Grid>
  
                  <FormField
                      label={getScoringPlaceholder()}
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Write a criteria description that the candidate has to meet to be evaluated for this score. 
                        </p>
                      </>}
                      style={{ marginBottom: '16px' }}
                      multiline
                      minRows={8}
                      placeholder={getScoringPlaceholder()}
                      value={addScoring.currentSignal[scoringActiveCriteria]}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                      />
  
                  <FormField
                      label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                      value={addScoring.currentSignal.weight}
                      type="Number"
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                    />
                </Grid>}
                  
                {/* Error Messages */}
                <div className="d-flex" style={{ width: '100%' }}>
                  {errMsgs.length > 0 &&
                    errMsgs.map((errMsg) => {
                      return (
                        <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                      )
                    })
                  }
                </div>
              </DialogContent>
              <DialogActions>
                <div className="d-flex flex-row" style={{ width: '100%', padding: '16px' }}>
                  <Button className="btn-main gray outlined"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {cancelShowAddModal('signal-assist')
                      setErrMsgs([])}}>
                      Cancel
                  </Button>
                  {scoringAssistStep > 0 && <Button className="btn-main gray outlined"
                    style={{ marginLeft: '12px' }}
                    onClick={() => {setScoringAssistStep((prev) => prev - 1 )
                      setErrMsgs([])}}>
                      Back
                  </Button>}
                  {scoringAssistStep === 0 && <Button className="btn-main assistant"
                    disabled={
                      scoringGenerating || (addScoring.currentSignal.signal == "" || addScoring.currentSignal.description == "")
                    }
                    style={{ marginLeft: '12px' }}
                    onClick={() => handleGenerateScoringCriteria({
                      signal: addScoring.currentSignal.signal,
                      scale: addScoring.currentSignal.scale,
                      context: addScoring.currentSignal.description
                    })}>
                      { scoringGenerating ? 
                      <>
                        Generating...
                        &nbsp;&nbsp;
                        <CircularProgress
                          className="m-auto"
                          size={14}
                          style={{ color: "white", marginLeft: "8px" }}
                          />
                      </>
                      : <>
                      <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                      Generate
                      </> }
                  </Button>}
                  
                  {scoringAssistStep === 1 && <Button className="btn-main"
                    style={{ marginLeft: '12px' }}
                    onClick={() => {saveAddScoring()}}>
                      Save
                  </Button>}
                </div>
              </DialogActions>
          </Dialog>
        </div>
      )
    }
  
    const renderReviewTest = () => {
      const question_sections = groupBy(addQuestions.questions, 'section')
      // show sections only if at least 2 different sections specified
      const showSections = Object.keys(question_sections).length > 1;
  
      return (
        <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px', marginBottom: '40px' }}>
          <Paper style={{ minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '8px', border: '1px solid #D9D9D9' }}>
          <h3 className="section-heading" style={{ marginTop: 0 }}>Review Interview</h3>
          <p className="color-gray" style={{ fontSize: 14, marginTop: 0, marginBottom: '24px' }}>Review details about the interview. You can make changes to this at any time after saving.</p>
  
          <div className="d-flex flex-col">
            <FormEditable
              style={{ marginBottom: '24px' }}
              label="Interview Title"
              placeholder={roleBackground.interviewTitle}
              value={roleBackground.interviewTitle}
              onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}>
            </FormEditable>
  
            <FormEditable
              style={{ marginBottom: '24px' }}
              label="Job Title"
              placeholder={roleBackground.jobTitle}
              value={roleBackground.jobTitle}
              onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}>
            </FormEditable>
  
            {/* Questions */}
            <h5 className="d-flex input-label">
              Questions 
                <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                  onClick={() => setActiveStep(1)}></DriveFileRenameOutlineIcon>
            </h5>
            <div className="card" style={{ marginBottom: '24px' }}>
              <Grid container style={{ marginTop: 0 }}>
                <TableContainer style={{ marginTop: 0 }}>
                  <Table style={{ marginTop: 0 }}>
                    <TableHead>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px',
                                borderRadius: '8px'
                              }
                            }}>
                        <TableCell style={{ width: '70%' }}>
                          Question
                        </TableCell>
                        <TableCell style={{ minWidth: '72px' }}>Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(question_sections).sort(sectionSort).map((keyName, keyIndex) => (
                        <SortableContext
                          items={question_sections[keyName].map((question, idx) => {return question.id })}
                          strategy={verticalListSortingStrategy}
                        >
                          {showSections && keyName != 'undefined' && <TableSection label={keyName}></TableSection>}
                          {question_sections[keyName].map((question, idx) => (
                            <>
                              <TableRow
                                sx={{
                                  position: 'relative',
                                  border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                              >
                                <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                                  {question.question}
                                </TableCell>
                                <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{secondsToTimeLimitReadable(question.time_limit)}</TableCell>
                              </TableRow>
                            </>
                          ))}
                        </SortableContext>
                      ))
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
  
            
            {/* Scorecard */}
            <h5 className="d-flex input-label">
              Scorecard 
                <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                  onClick={() => setActiveStep(2)}></DriveFileRenameOutlineIcon>
            </h5>
            <div className="card" style={{ marginBottom: '24px' }}>
              <Grid container style={{ marginTop: 0 }}>
                <TableContainer style={{ marginTop: 0 }}>
                  <Table style={{ marginTop: 0 }}>
                    <TableHead>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px',
                                borderRadius: '8px'
                              }
                            }}>
                        <TableCell style={{ width: '70%' }}>
                          Name
                        </TableCell>
                        <TableCell style={{ minWidth: '72px' }}>
                          Weight
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addScoring.signals.map((signal, idx) => (
                        <>
                          <TableRow
                            sx={{
                              position: 'relative',
                              border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                          >
                            <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                              {signal.signal}
                            </TableCell>
                            <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{signal.weight}</TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
  
  
            {/* Scorecard */}
            <h5 className="d-flex input-label">
              Structured Outputs 
                <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                  onClick={() => setActiveStep(3)}></DriveFileRenameOutlineIcon>
            </h5>
            <div style={{ marginBottom: '24px' }}>
              {addOutputs.outputs.length > 0 ? addOutputs.outputs.map((output) => (
                    <div className="d-flex flex-col section-heading" style={{ padding: '12px', borderRadius: '8px', border: '1px solid lightgray' }}>
                        <>
                          <div className="section-heading">
                            <ChipTag variant="default" text={output.key} style={{ width: 'max-content' }} />
                          </div>
                          <div className="d-flex flex-col" style={{ marginLeft: '8px' }}>
                            <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px' }}>
                              Extraction Instructions
                            </h5>
                            <p className="section-body" style={{ fontSize: '14px' }}>
                              {output.extraction_instructions}
                            </p>
  
                            <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px'  }}>
                              Type
                            </h5>
                            <p className="section-body" style={{ fontSize: '14px' }}>
                              {output.data_type}
                            </p>
                          </div>
                        </>
                    </div>
                  ))
                :
                <>
                  <div className="d-flex flex-col section">
                    <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                      No outputs.
                    </p>
                  </div>
                </>
              }
            </div>
          </div>
  
          <FormField
            label="Custom Interview Prompt (optional)"
            multiline
            minRows={6}
            placeholder=""
            value={reviewTest.prompt}
            onChange={(e) => {setReviewTest({ ...reviewTest, prompt: e.target.value})}}
            />
  
          </Paper>
        </div>
      )
    }
  
    const renderActiveStepComponent = () => {
      switch (activeStep) {
        case -1:
          switch (creationFlow) {
            case '':
              return renderFlowPrompt();
            case 'jd':
              return renderJDUpload();
            case 'template':
              return renderTemplateFlow();
            case 'self':
            default:
              return setActiveStep(0);
          }
        case 0:
          return renderRoleBackground();
        case 1:
          return renderAddQuestions();
        case 2:
          return renderAddScoring();
        case 3:
          return renderAddOutputs();
        case 4:
          return renderReviewTest();
        default:
          return null;
      }
    }
  
    return (
      <div className="d-flex flex-col" style={{ padding: 0, backgroundColor: 'white', height: '100%' }}>
        {activeStep > -1 ? <div className="d-flex flex-row" style={{ padding: '16px', borderBottom: '1px solid lightgray' }}>
  
          <button className="btn-main d-flex my-auto" style={{ marginRight: 'auto' }}
            onClick={() => handleBack()}>
            <NavigateBefore className="my-auto" fontSize="24px"/>
            Back
          </button>
  
          <Stepper activeStep={activeStep}
            className="d-flex flex-row"
            style={{ width: 'auto', margin: 'auto !important' }}
            sx={{
              ['& .MuiStep-root'] : {
                display: 'flex',
                flex: 0,
                paddingLeft: 0
              }
            }}
            alternativeLabel connector={
              <NavigateNext className="m-auto" fontSize="14px" style={{ color: 'var(--color-gray)', paddingRight: '8px' }} />
            }>
            {steps.map((label, index) => {
              const stepProps= {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel StepIconComponent={
                    () =>
                      <div className="d-flex flex-row"
                        style={{ borderRadius: '32px', border: '1px solid lightgray', padding: '6px',
                          backgroundColor: activeStep >= index ? 'black': 'white'
                          }}>
                        <Avatar className="m-auto"
                          sx={{ width: '18px', height: '18px' }}
                          style={{ backgroundColor: activeStep >= index ? 'white': 'var(--color-lightgray)',
                          margin: 0 }}
                        >
                          <p className="m-auto" style={{ fontSize: '12px', color: 'var(--color-main)'}}>{index+1}</p>
                        </Avatar>
                        <h5 style={{ fontSize: '14px', margin: 'auto 8px',
                          color: activeStep >= index ? 'white': 'var(--color-gray)' }}>{label}</h5>
                      </div>} {...labelProps}>
                    </StepLabel>
                </Step>
              );
            })}
          </Stepper>
  
          <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto' }}
            onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Create Interview' : 'Next'}
              <NavigateNext className="my-auto" fontSize="24px" />
          </button>
  
        </div>
        :
        <div className="d-flex flex-row" style={{ marginRight: 'auto', padding: '16px' }}>
          <button className="btn-main small d-flex gray outlined"
            onClick={() => {navigate(process.env.REACT_APP_HOME_TAB_ENABLED === "true" ? "/home" : "/interviews")}}>
            <ArrowBack fontSize="14px" className="color-heading m-auto" />
          </button>
          <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>{process.env.REACT_APP_HOME_TAB_ENABLED === "true" ? "Home" : "My Interviews"}</h5>
        </div>
        }
        {/* Error Messages Header */}
        {errMsgs.length > 0 &&
          !(showAddQuestionInput || showAddOutputInput || showAddSignalInput) &&
          errMsgs.map((errMsg) => {
            return (
              <Alert severity="error" style={{ width: '100%', margin: 'auto'}}>{errMsg}</Alert>
            )
          })
        }
        <div className="d-flex flex-row" style={{ flex: 1, maxHeight: 'calc(100vh - 1px)', overflow: 'auto' }}>
          {renderActiveStepComponent()}
        </div>
      </div>
    )
  }