export function buildUniqueSessionLink(sessionId, callType) {
  const env = String(process.env.REACT_APP_ENV)

  let base = 'https://beta.techinterviewer.ai'
  if (env === 'staging') {
    base = 'https://staging.techinterviewer.ai'
  }
  if (env === 'development') {
    base = 'http://localhost:3001'
  }

  if (callType === 'phone') {
    base += '/phone'
  }

  return base + '?session=' + sessionId
}

export const DEFAULT_SCALE = 4;

export const normalizeScore = (score, scale) => {
  if (score === undefined || score === -1) return 0;
  if (Number(score) === 0) return 1;
  return (Number(score) * 100 / scale);
}

export const getColorFromScore = (score, scale) => {
  if (score === undefined || score === -1) return '#D6D6D6';

  const range = normalizeScore(score, scale);
  if (range < 50) return '#E02E2E'
  if (range < 75) return '#E27C1E'
  else return '#5C9B0A';
}

export const getColorBgFromScore = (score, scale) => {
  if (score === undefined || score === -1) return '#F0F0F0';

  const range = normalizeScore(score, scale);
  if (range < 50) return '#F8D5D5'
  if (range < 75) return '#FFF7ED'
  else return '#EEF5E6';
}

export const secondsToTimeLimitReadable = (seconds) => {
  if (seconds === undefined) return "";
  if (seconds < 60) {
    return `${seconds} sec.`;
  }
  if (seconds % 60 === 0) {
    return `${Math.floor(seconds / 60)} min.`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes} min. ${remainingSeconds} sec.`;
}
